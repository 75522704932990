import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Emppramotion } from 'src/app/ClassFiles/emppramotion';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-salaryincrementpopup',
  templateUrl: './salaryincrementpopup.component.html',
  styleUrls: ['./salaryincrementpopup.component.scss']
})
export class SalaryincrementpopupComponent implements OnInit {
  incrementForm: FormGroup;
  grosssalary;
  incement;
  totgross;
  newctc;
  Salary;
  empid;
  newSalary;
  Salarytable;
  oldctc;
  finyear
  submited = new EventEmitter<string>();
  count;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private service: MasterServiceService,public dialog: MatDialog,private router: Router,private route: ActivatedRoute,private fb:FormBuilder,private toastr: ToastrService) 
  { 

this.empid = data.id

 service.GetEmployeeSalaryBreakDown(data.id).subscribe((data1: any) => {
          
  this.Salary = data1;
  this.grosssalary = data1[0].grosssalary;
  this.totgross = data1[0].grosssalary;
  this.newctc = data1[0].ctc;
  this.oldctc = data1[0].ctc;
});
service.GetEmployeePromotion(data.id).subscribe((data: any) => {
  this.Salarytable = data;
  if (data == "") {
  
    this.count = 0;
  }
  else if (data == null && data[0] == null) {
  
    this.count = 0;
  }
  else {
    this.count = data[0].tRecordCount;
  }
});
this.service.GetName("FinanacialYear","yearname","status=0").subscribe((data: any) => {
  this.finyear= data;
});
  }

  ngOnInit() {
    this.incrementForm = this.fb.group({

      grosssalary: [''],
      incrementsalary:  [''],
      totsalary:  [''],
      ctc:  [''],

    });
  }
  clear(){
    //localStorage.removeItem('empid');
     this.dialog.ngOnDestroy();
    }

    changeincrement(event1) {

      this.incement = event1;
      this.totgross = +this.grosssalary + +this.incement

      this.service.GetNewGrossSalaryDetail(this.empid, this.totgross).subscribe((data2: any) => {
          
        this.newSalary = data2;
        this.newctc = data2.ctc;
      });
        }
     
      // this.newctc = 12000;

  



  submit(){

    const data: Emppramotion = new Emppramotion();
    data.empid = this.empid;
    data.finyear = this.finyear;
    data.oldctc = this.oldctc
    data.oldgross = this.grosssalary;
    data.incregross = this.incement;
    data.newgross = this.totgross;
    data.newctc = this.newctc;
    // this.incrementForm.reset();
    this.service.InsertEmployeePromotion(data).subscribe((res) => {

      this.submited.emit(res.toString());
      this.toastr.success("Increment Updated Successfully")
     
      this.service.GetEmployeePromotion(this.empid).subscribe((data: any) => {this.Salarytable = data;});
     
      
    },
      error => {
        console.log(error);
      }
    );
  }
}
