import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { City } from 'src/app/ClassFiles/city';
import { Distirct } from 'src/app/ClassFiles/distirct';
@Injectable({
  providedIn: 'root'
})
export class CenterService {

  constructor(private http: HttpClient) { }

  FillDropDown(tablename, column1, column2, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FillDropDown?tablename=' + tablename + '&column1=' + btoa(column1) + '&column2=' + btoa(column2) + '&whr=' + btoa(where));
  }
  CenterList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'CenterList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  InsertCenter(pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertCenter/' + pgid + '/', data);
  }

  FillEmpByDepDes(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FillEmpByDepDes?whr=' + btoa(where));
  }

  GetStatewiseCityList(stname: string) {
    return this.http.get<City[]>(environment.apiUrl + 'JankariPortal/' + 'StatewiseCityList/' + stname);
  }
  GetStatewiseDistrictList(stname: string) {
    return this.http.get<Distirct[]>(environment.apiUrl + 'JankariPortal/' + 'StatewiseDistrictList/' + stname);
  }
  GetDistrictwiseCityList(dtname: string) {
    return this.http.get<City[]>(environment.apiUrl + 'JankariPortal/' + 'DistrictwiseCityList/' + dtname);
  }
  GetStateList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'StateList');
  }
  GetCityList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'CityList');
  }
  GetDistrictList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'DistrictList');
  }


  AddCenterType(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterType/' + centid + '/' + userid + '/', data);
  }


  AddCenterContactInfo(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterContactInfo/' + centid + '/' + userid + '/', data);
  }

  AddCenterotherInfo(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterotherInfo/' + centid + '/' + userid + '/', data);
  }

  UpdateCenterOtherInfo(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateCenterOtherInfo/' + centid + '/' + userid + '/', data);
  }


  AddCenterInfra(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterInfra/' + centid + '/' + userid + '/', data);
  }




  RemoveCenterInfra(infraid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveCenterInfra/' + infraid);
  }


  AddCenterGST(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterGST/' + centid + '/' + userid + '/', data);
  }


  UploadCenterGST(centid: string, userid: string, gstapp: string, academicyear: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadCenterGST/' + centid + '/' + userid + '/' + gstapp + '/' + academicyear, formData);
  }

  GetCenterDetails(centid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterDetails/' + centid);
  }

  GetIDICenterDetails(centid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDICenterDetails/' + centid);
  }

  CenterTypeList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'CenterTypeList/' + centid);
  }

  CenterInfraList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'CenterInfraList/' + centid);
  }
  GetCenterPartnerList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterPartnerList/' + centid);
  }
  GetSancharakList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSancharakList/' + centid);
  }
  GetAgreementList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAgreementList/' + centid);
  }
  GetGSTList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetGSTList/' + centid);
  }
  GetCenterotherInfoList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterotherInfoList/' + centid);
  }
  GetCenterComputerList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterComputerList/' + centid);
  }
  GetCenterRateList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterRateList/' + centid);
  }


  UploadAgreement(centid: string, userid: string, stdt: Date, endt: Date, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadAgreement/' + centid + '/' + userid + '/' + stdt + '/' + endt, formData);
  }

  RemoveAgreement(agid: number) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveAgreement/' + agid);
  }

  GetAgreementLiteList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAgreementLiteList/' + centid);
  }

  UploadAgreementLite(centid: string, userid: string, stdt: Date, endt: Date, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadAgreementLite/' + centid + '/' + userid + '/' + stdt + '/' + endt, formData);
  }

  RemoveAgreementLite(agid: number) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveAgreementLite/' + agid);
  }

  AddCenterComputer(centid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterComputer/' + centid + '/', data);
  }
  RemoveCenterComputer(hardwareid: number) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveCenterComputer/' + hardwareid);
  }
  AddCenterPartner(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterPartner/' + centid + '/' + userid, data);
  }
  RemoveCenterGST(gid: number) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveCenterGST/' + gid);
  }

  RemoveCenterPartner(cpid: number) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveCenterPartner/' + cpid);
  }
  UpdateCenter(userid: string, role: string, pgid: string, centid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateCenter/' + userid + '/' + role + '/' + pgid + '/' + centid, data);
  }

  RemoveCenterType(ctid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveCenterType/' + ctid);
  }
  AddCenterUBSInfo(centid: string, userid: string, data1: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterUBSInfo/' + centid + '/' + userid, data1);
  }
  GetCenterUBSInfoList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterUBSInfoList/' + centid);
  }

  RemoveCenterCourse(centcourseid: number) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveCenterCourse/' + centcourseid);
  }
  GetCenterCourseDetailsList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterCourseDetailsList/' + centid);
  }
  AddCenterCourse(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterCourse/' + centid + '/' + userid, data);
  }

  AddCenterSancharak(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCenterSancharak/' + centid + '/' + userid, data);
  }
  RemoveCenterSancharak(asid: number) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveCenterSancharak/' + asid);
  }
  GetIDIBatch(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIBatch/' + centid);
  }
  InsertIDIBatch(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertIDIBatch/', data);
  }

  RptCenter(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'RptCenter/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCenterLite(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCenterLite/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptSchoolLite(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSchoolLite/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  RptIDICenter(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'RptIDICenter/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  AddCALPlanAttendance(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALPlanAttendance', data);
  }
  GetCALPlanAttendanceList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPlanAttendanceList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  InsertVendorEmployee(vendorid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertVendorEmployee/' + vendorid, data);
  }

  EmployeeVendorList(whr: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'EmployeeVendorList?whr=' + btoa(whr));
  }

  GetVendorEmployeeDetail(veid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetVendorEmployeeDetail/' + veid);
  }

  UpdateVendorEmployee(veid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateVendorEmployee/' + veid, data);
  }

  GetPlanAttendanceDetails(month: string, year: string, schoolid: string, academicyear: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlanAttendanceDetails/' + schoolid + '/' + month + '/' + year + '/' + academicyear);
  }

  InsertVendor(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertVendor/', data);
  }
  VendorList(where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'VendorList/' + '?whr=' + btoa(where));
  }
  UpdateVendor(vendorid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateVendor/' + vendorid, data);
  }
  GetVendorDetail(vendorid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetVendorDetail/' + vendorid);
  }

  GetStudentAssignmentDetailList(schoolid: string, standard: string, batchid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStudentAssignmentDetailList/' + schoolid + '/' + standard + '?batchid=' + batchid);
  }

  AddActualStudentAssignment(createby: string, date, batch: string, standard: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddActualStudentAssignment?date=' + date + '&standard=' + standard + '&batchid=' + batch + '&createby=' + createby, data);
  }
  GetStudentAssignmentList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStudentAssignmentList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetStudentAssignmentDetailData(schoolid: string, standard: string, batchid: string, date): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStudentAssignmentDetailData?date=' + date + '&standard=' + standard + '&batchid=' + batchid + '&schoolid=' + schoolid);
  }

  UpdateActualStudentAssignment(createby: string, date, batch: string, standard: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateActualStudentAssignment?date=' + date + '&standard=' + standard + '&batchid=' + batch + '&createby=' + createby, data);
  }

  GetSMSActivityList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSMSActivityList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSMSActivityData(said: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSMSActivityData/' + said);
  }
  GetSMSActivityStudentList(said: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSMSActivityStudentList/' + said + '?whr=' + btoa(whr));
  }
  GetSchoolwisestarndard(schoolid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolwisestarndard/' + schoolid);
  }
  UBSSMSSend(empid: string, schoolid: string, date: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UBSSMSSend/' + empid + '/' + schoolid + '/' + date, data);
  }

  GetRptSMSSubjectSummary(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSMSSubjectSummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptSMSSummary(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSMSSummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptSMSDatewiseCount(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSMSDatewiseCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetStatewiseEnrollment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStatewiseEnrollment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCitywiseEnrollment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCitywiseEnrollment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSchoolwiseEnrollment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolwiseEnrollment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptPlanVSActualTeamLeaderStudAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanVSActualTeamLeaderStudAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptPlanVSActualStateStudAttendance(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanVSActualStateStudAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  RemovePLanAtteandnce(paid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemovePLanAtteandnce/' + paid);
  }

  GetRptPlanVSActualTeamLeaderStudAttendanceATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanVSActualTeamLeaderStudAttendanceATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptPlanVSActualStateStudAttendanceATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanVSActualStateStudAttendanceATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetIDIAssesementAssignmentList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIAssesementAssignmentList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  AddIDIAssessmentAssignmentEvalution(dependancyid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddIDIAssessmentAssignmentEvalution/' + dependancyid, data);
  }

  GetRptIDIFeedbackList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIFeedbackList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  EmployabilityReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIEvalautionEmployabilityReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  FinancialLiteracySkillReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIEvalautionFinancialLiteracySkillReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCenterwiseDropoutList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterwiseDropoutList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetDropoutMasterList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetDropoutMasterList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetApprovalDropoutList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetApprovalDropoutList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddRequestDroupout(userid: string, role: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddRequestDroupout/' + userid + '/' + role, data);
  }
  UpdateDroupout(userid: string, role: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateDroupout/' + userid + '/' + role, data);
  }

  AddCALPlanAttendanceTL(tlid: string, centid: string, schoolid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALPlanAttendanceTL/' + tlid + '/' + centid + '/' + schoolid, data);
  }

  ExportPIFRate(type: string, centid: string, schoolid: string, SchType: string, startdate, enddate): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'ExportPIFRate?type=' + type + '&centid=' + centid + '&schoolid=' + schoolid + '&SchType=' + SchType + '&startdate=' + startdate + '&enddate=' + enddate);
  }
  AddPIFRate(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddPIFRate/' + userid, data);
  }
  PIFRateList(whr: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'PIFRateList?whr=' + btoa(whr));
  }
  PIFRateInfoDetails(type: string, whr: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'PIFRateInfoDetails?type=' + type + '&whr=' + whr);

  }
  PIFRateDataDetails(type: string, crateid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'PIFRateDataDetails/' + type + '/' + crateid);
  }
  UpdatePIFRateDetails(crateid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePIFRateDetails/' + crateid + '/' + userid, data);
  }
  GetPIFCenterGeneratedBillList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFCenterGeneratedBillList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPIFSchoolGeneratedBillList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFSchoolGeneratedBillList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPIFCenterMonthBillList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFCenterMonthBillList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPIFSchoolMonthBillList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFSchoolMonthBillList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPIFBillingDetail(billid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFBillingDetail/' + billid);
  }
  GetPIFBillingRateStandwise(billid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFBillingRateStandwise/' + billid);
  }
  GetPIFBillModificationFiles(billid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFBillModificationFiles/' + billid);
  }
  GetPIFBillingBalance(billid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFBillingBalance/' + billid);
  }


  // Hardware Module Api

  GetHardwareCallList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetHardwareCallList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  InsertHardwareCall(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertHardwareCall/', data);
  }

  AddHardwareCallProblem(callid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddHardwareCallProblem/' + callid, data);
  }

  GetHardwareCallProblem(callid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetHardwareCallProblem/' + callid);
  }

  RemoveHardwareCallProblem(hid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveHardwareCallProblem/' + hid);
  }

  AddHardwareCallVisit(callid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddHardwareCallVisit/' + callid, data);
  }

  GetHardwareCallVisit(callid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetHardwareCallVisit/' + callid);
  }

  RemoveHardwareCallVisit(hvid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveHardwareCallVisit/' + hvid);
  }

  AddHardwareCallMaterial(callid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddHardwareCallMaterial/' + callid, data);
  }

  GetHardwareCallMaterial(callid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetHardwareCallMaterial/' + callid);
  }

  RemoveHardwareCallMaterial(hvid: string, rid: number) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveHardwareCallMaterial/' + hvid + '?rid=' + rid);
  }

  UpdateHardwareCall(callid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateHardwareCall/' + callid + '/' + userid, data);
  }

  GetCenterHardwareCallDetail(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterHardwareCallDetail/' + centid);
  }

  GetHardwareCallDetail(userid: string, callid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetHardwareCallDetail/' + userid + '/' + callid);
  }


  GetCityWiseAllSummaryCAL(userid: string, role: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCityWiseAllSummaryCAL/' + userid + '/' + role + '/' + pgid);
  }
  GetStateWiseAllSummaryCAL(pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStateWiseAllSummaryCAL/' + pgid);
  }

  GetCityWiseAllSummaryIDI(userid: string, role: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCityWiseAllSummaryIDI/' + userid + '/' + role + '/' + pgid);
  }
  GetStateWiseAllSummaryIDI(pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStateWiseAllSummaryIDI/' + pgid);
  }
  GetHardwarecallAlert(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetHardwarecallAlert/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  // ATAL Enrollment summary 2.0

  GetStatewiseEnrollmentATAL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStatewiseEnrollmentATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetCitywiseEnrollmentATAL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCitywiseEnrollmentATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetSchoolwiseEnrollmentATAL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolwiseEnrollmentATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetGenderwiseEnrollmentATAL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetGenderwiseEnrollmentATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetPartnerStdCentWise(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStdCentWise?whr=' + btoa(where));
  }
  GetPartnerGenderEnrollmentSummary(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGenderEnrollmentSummary?whr=' + btoa(where));
  }
  GetPartnerGradewiseCenterAttendance(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGradewiseCenterAttendance?whr=' + btoa(where));
  }
  GetCALAssessmentGraph(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALAssessmentGraph/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCenterAssessmentGraph(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetCALCenterAssessmentGraph/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPartnerGradewiseCenterAttendanceStd(where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetPartnerGradewiseCenterAttendanceStd?whr=' + btoa(where));
  }
  GetGradewiseCenterAttendanceStd(where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetGradewiseCenterAttendanceStd?whr=' + btoa(where));
  }
  HardwareCallMaterialMail(callid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'HardwareCallMaterialMail/' + callid, data);
  }
  AddATALPlanAttendanceTL(tlid: string, centid: string, schoolid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddATALPlanAttendanceTL/' + tlid + '/' + centid + '/' + schoolid, data);
  }

  InsertPlanSancharakTraining(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertPlanSancharakTraining/', data);
  }
  GetPlanSancharakTrainingList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlanSancharakTrainingList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  UpdatePlanSancharakTraining(presrid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePlanSancharakTraining?presrid=' + presrid, data);
  }
  GetPlanSancharakTrainingDetails(presrid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlanSancharakTrainingDetails/' + presrid);
  }

  GetRptCenterAgreement(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCenterAgreement?whr=' + btoa(where));
  }
  DownloadFoundationData(tlid: string) {
    return this.http.get(environment.apiUrl + 'Evaluation/' + 'DownloadFoundationData/' + tlid, {
      responseType: 'blob'
    });
  }
  ModuleWiseATALSummary(examtype, pgid: string, acyear: string): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'ModuleWiseATALSummary/' + examtype + '/' + pgid + '/' + acyear);
  }

  GetCALSancharakTrainingList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALSancharakTrainingList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  InsertActualSancharakTraining(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertActualSancharakTraining', data);
  }

  AddSancharakTraninigPhoto(strid: string, userid: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    // console.log(fileToUpload)
    return this.http.post(environment.apiUrl + 'Center/' + 'AddSancharakTraninigPhoto/' + strid + '/' + userid, formData);
  }

  GetSancharakTrainingPic(id: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSancharakTrainingPic/' + id);
  }

  GetSancharakTrainingDetails(strid: String): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSancharakTrainingDetails/' + strid);
  }

  UpdateSancharakTrainingDetail(strid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSancharakTrainingDetail?strid=' + strid, data);
  }

  RemoveSancharakTraninigPhoto(id: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveSancharakTraninigPhoto/' + id);
  }

  UpdateSancharakTrainingAttendences(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSancharakTrainingAttendences/' + createby, data);
  }

  UpdateSancharakTrainingFeedback(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSancharakTrainingFeedback/' + createby, data);
  }

  GetSancharakTrainingAttendencesDetail(strid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSancharakTrainingAttendencesDetail/' + strid);
  }

  GetSancharakTrainingFeedbacksDetail(strid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSancharakTrainingFeedbacksDetail/' + strid);
  }

  GetSancharakTrainingAttendenceSection(strid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSancharakTrainingAttendenceSection/' + strid);
  }

  GetRptSancharkTeacherTrainingSummary(Userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSancharkTeacherTrainingSummary/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetRptActualSancharakTraining(Userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptActualSancharakTraining/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptPrePlannedSancharkTraining(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPrePlannedSancharkTraining/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetMySchoolSchoolSurveyList(whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMySchoolSchoolSurveyList?whr=' + btoa(whr));
  }
  GetRptMySchoolSchoolSurvey(whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMySchoolSchoolSurvey?whr=' + btoa(whr));
  }
  GetMySchoolSurveyDetail(surveyid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMySchoolSurveyDetail/' + surveyid);
  }

  GetMySchoolSurveyEnrollDetail(surveyid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMySchoolSurveyEnrollDetail/' + surveyid);
  }


  AddActualStudentAssignmentATAL(date: Date, standard: string, batchid: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddActualStudentAssignmentATAL?date=' + date + '&standard=' + standard + '&batchid=' + batchid + '&createby=' + createby, data);
  }


  UpdateActualStudentAssignmentATAL(date: Date, standard: string, batchid: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateActualStudentAssignmentATAL?date=' + date + '&standard=' + standard + '&batchid=' + batchid + '&createby=' + createby, data);
  }

  GetCityWiseAllSummaryCALNew(userid: string, role: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCityWiseAllSummaryCALNew/' + userid + '/' + role + '/' + pgid);
  }

  GetCALFinalAssessmentLevelStateCity(userid: string, role: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + '/GetCALFinalAssessmentLevelStateCity/' + userid + '/' + role + '/' + pgid + '/');
  }

  GetCALFinalAssessmentLevelSchool(userid: string, role: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelSchool/' + userid + '/' + role + '/' + pgid);
  }
  GetCALFinalAssessmentLevelStudent(Userid: string, role: string, pgid: string, state: string, city: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelStudent/' + Userid + '/' + role + '/' + pgid + '/' + state + '/' + city);
  }
  FillDropDownUserwisewithInactive(us: string, ur: string, pgid: string, whr: string, col1: string, col2: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'FillDropDownUserwisewithInactive?us=' + us + '&ur=' + ur + '&pgid=' + pgid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }

  GetCALFinalAssessmentLevelStateGraph(Userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelStateGraph/' + Userid + '/' + role + '/' + pgid);
  }
  GetCALFinalAssessmentLevelCityGraph(Userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelCityGraph/' + Userid + '/' + role + '/' + pgid);
  }
  GetCALFinalAssessmentLevelStandardGraph(Userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelStandardGraph/' + Userid + '/' + role + '/' + pgid);
  }
  GetCALFinalAssessmentLevellanguageGraph(Userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevellanguageGraph/' + Userid + '/' + role + '/' + pgid);
  }
  GetCALGradewiseAttedance(userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedance/' + userid + '/' + role + '/' + pgid);
  }
  GetCALGradewiseAttedanceStudent(Userid: string, role: string, pgid: string, state: string, city: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedanceStudent/' + Userid + '/' + role + '/' + pgid + '/' + state + '/' + city);
  }
  GetBatchCount(schoolid: string, standard: string, batch: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetBatchCount/' + schoolid + '/' + standard + '/' + batch);
  }
  GetCenterwiseDropoutIDIList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterwiseDropoutIDIList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddRequestDroupoutIDI(userid: string, role: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddRequestDroupoutIDI/' + userid + '/' + role, data);
  }
  GetDropoutMasterIDIList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetDropoutMasterIDIList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetApprovalDropoutIDIList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetApprovalDropoutIDIList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  UpdateDroupoutIDI(userid: string, role: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateDroupoutIDI/' + userid + '/' + role, data);
  }

  GetCityWiseAllSummaryIDINew(userid: string, role: string, pgid: string, acyear: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCityWiseAllSummaryIDINew/' + userid + '/' + role + '/' + pgid + '/' + acyear);
  }
  GetMyAreaSummaryIDI(userid: string, role: string, pgid: string, acyear: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMyAreaSummaryIDI/' + userid + '/' + role + '/' + pgid + '/' + acyear);
  }
  GetMyAreaSummaryCAL(userid: string, role: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMyAreaSummaryCAL/' + userid + '/' + role + '/' + pgid);
  }
  GetCALFinalAssessmentLevellangGraphCenter(centid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevellangGraphCenter/' + centid);
  }
  GetCALFinalAssessmentLevelStandardCenterGraph(centid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelStandardCenterGraph/' + centid);
  }
  GetCALGradewiseAttedancecenter(centid: string, month: string, year: number): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedancecenter/' + centid + '/' + month + '/' + year);
  }
  GetCALGradewiseAttedancePaiGraph(centid: string, month: string, year: number): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedancePaiGraph/' + centid + '/' + month + '/' + year);
  }


  GetStatewiseEnrollmentSancharak(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStatewiseEnrollmentSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCitywiseEnrollmentSancharak(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCitywiseEnrollmentSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetSchoolwiseEnrollmentSancharak(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolwiseEnrollmentSancharak/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttedanceschool(schoolid: string, month: string, year: number): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedanceschool/' + schoolid + '/' + month + '/' + year);
  }
  GetCALGradewiseAttedanceschoolPaiGraph(schoolid: string, month: string, year: number): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedanceschoolPaiGraph/' + schoolid + '/' + month + '/' + year);
  }
  GetCALFinalAssessmentLevelStandardSchoolGraph(schoolid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelStandardSchoolGraph/' + schoolid);
  }
  GetPartnerStdCityWise(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPartnerStdCityWise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGenderEnrollmentCitySummary(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetGenderEnrollmentCitySummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttCityPaiGraph(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttCityPaiGraph/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttedanceCity(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedanceCity/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCityAssessmentGraph(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALCityAssessmentGraph/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelStandardCity(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelStandardCity/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCitywiseCenterSchoolCount(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALCitywiseCenterSchoolCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishCitywiseCenterSchoolCount(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishCitywiseCenterSchoolCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelLanguageCity(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelLanguageCity/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddCALSchoolBatch(schoolid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALSchoolBatch/' + schoolid, data);
  }
  GetPartnerStdStateWise(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPartnerStdStateWise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGenderEnrollmentStateSummary(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetGenderEnrollmentStateSummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttStatePaiGraph(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttStatePaiGraph/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttedanceState(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedanceState/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALStateAssessmentGraph(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALStateAssessmentGraph/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelStandardState(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelStandardState/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALStatewiseCenterSchoolCount(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALStatewiseCenterSchoolCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishStatewiseCenterSchoolCount(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishStatewiseCenterSchoolCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelLanguageState(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelLanguageState/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptPlanvsActualAttedanceSchoolswise(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanvsActualAttedanceSchoolswise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptPlanvsActualAttedanceStudentwise(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanvsActualAttedanceStudentwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  InsertIDIActivityMaster(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertIDIActivityMaster/', data);
  }

  GetIDIActivityDetails(acid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIActivityDetails/' + acid);
  }
  GetIDIActivityList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIActivityList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptIDIActivity(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIActivity/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetIDIActivityDetailList(acid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIActivityDetailList/' + acid);
  }
  RemoveIDIActivityDetail(acdetailid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveIDIActivityDetail/' + acdetailid);
  }
  GetPartnerStdWise(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPartnerStdWise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGenderEnrollmentPartnerSummary(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetGenderEnrollmentPartnerSummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttPartnerPaiGraph(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttPartnerPaiGraph/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPartnerAssessmentGraph(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPartnerAssessmentGraph/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttedancePartner(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedancePartner/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelStandardPartner(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelStandardPartner/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelLanguagePartner(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelLanguagePartner/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPartnerwiseCenterSchoolCount(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPartnerwiseCenterSchoolCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishPartnerwiseCenterSchoolCount(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishPartnerwiseCenterSchoolCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }


  CenterListMyschool(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'CenterListMyschool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptIDIEvalautionEmployabilityNew(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIEvalautionEmployabilityNew/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptIDIEvalautionFinancialLiteracySkillNew(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIEvalautionFinancialLiteracySkillNew/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetLaptopAssignList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLaptopAssignList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLaptopAssignDetail(laid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLaptopAssignDetail/' + laid);
  }
  UpdateLaptopAssign(laid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateLaptopAssign/' + laid, data);
  }
  GetCCFellowLaptopNotification(userid: string, role: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCCFellowLaptopNotification/' + userid + '/' + role + '?whr=' + btoa(whr));
  }
  UpdateLaptopAssignCCfellow(userid: string, role: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateLaptopAssignCCfellow/' + userid + '/' + role, data);
  }
  GetLaptopSubmissionList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLaptopSubmissionList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLaptopSubmissionNotificationList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLaptopSubmissionNotificationList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLaptopSubmitDetail(laid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLaptopSubmitDetail/' + laid);
  }
  UpdateFellowLaptopSubmit(laid: string, userid: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateFellowLaptopSubmit/' + laid + '/' + userid, data);
  }
  UpdateLaptopSubmitCCPC(userid: string, role: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateLaptopSubmitCCPC/' + userid + '/' + role, data);
  }
  GetRptLaptopTracker(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptLaptopTracker/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCenterParentbatchDetail(centid: string, batchid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterParentbatchDetail/' + centid + '/' + batchid);
  }

  AddIDICaseStudy(data: any): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddIDICaseStudy/', data);
  }
  UpdateIDICaseStudyDetail(csid: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateIDICaseStudyDetail/' + csid, data);
  }
  GetIDICaseStudyDetail(csid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDICaseStudyDetail/' + csid);
  }
  GetIDICaseStudyList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDICaseStudyList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  UpdateIDICaseStudyCC(userid: string, role: string, data: any): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateIDICaseStudyCC/' + userid + '/' + role, data);
  }
  UploadIDICaseStudy(csid: string, fileToUpload: any): any {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadIDICaseStudy/' + csid, formData);
  }
  GetIDICaseStudyNotificationList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDICaseStudyNotificationList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptIDICaseStudy(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDICaseStudy/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCaseStudyUploadetail(csid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCaseStudyUploadetail/' + csid);
  }
  CenterSancharakList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'CenterSancharakList/' + centid);
  }

  UploadCenterComputer(createby: string, centid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadCenterComputer/' + createby + '/' + centid, data);
  }

  UploadCenterInfra(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadCenterInfra/' + centid + '/' + userid, data);
  }


  GetIDIActivityNotification(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIActivityNotification/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetIDIActivityNotificationDetail(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIActivityNotificationDetail/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  ApproveActivityDetail(userid: string, role: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'ApproveActivityDetail/' + userid + '/' + role, data);
  }

  UploadIDIActivity(doctype: string, acid: string, fileToUpload: File, createby: string) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadIDIActivity/' + doctype + '/' + acid + '/' + createby, formData);
  }

  InsertLapotopAssignment(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertLapotopAssignment?createdby=' + createby, data);
  }

  GetLaptopMasterList() {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLaptopMasterList');
  }


  GetSchoolwiseEnrollmentMyschool(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolwiseEnrollmentMyschool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetRptGenderwiseEnrollmentMySchool(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptGenderwiseEnrollmentMySchool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCitywiseEnrollmentMyschool(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCitywiseEnrollmentMyschool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetStatewiseEnrollmentMyschool(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStatewiseEnrollmentMyschool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }


  GetCALGradwiseAssessment(userid: string, role: string, pgid: string, standard: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradwiseAssessment/' + userid + '/' + role + '/' + pgid + '/' + standard);
  }

  GetRptHardwareCall(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptHardwareCall/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetCALBaselinecompetencyStd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd1(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd2(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd3(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinecompetencyStd4(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinecompetencyStd4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentBaseline5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentBaseline5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALBaselineAssessmentLevelSchool(Userid: string, role: string, pgid: string, examtype: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselineAssessmentLevelSchool/' + Userid + '/' + role + '/' + pgid + '/' + examtype);
  }

  GetCAlSchoolAttAlert(userid: string, role: string, pgid: string, month: string, year: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCAlSchoolAttAlert/' + userid + '/' + role + '/' + pgid + '/' + month + '/' + year + '?whr=' + btoa(where))
  }
  GetMyESchoolAssessmentGradwiseList(userid: string, role: string, pgid: string, schoolid: string, batchid: string, standard: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMyESchoolAssessmentGradwiseList/' + userid + '/' + role + '/' + pgid + '/' + schoolid + '/' + batchid + '/' + standard)
  }
  // AddMyESchoolAssessmentGradewise(createby: string, status: string, data: any) {
  //   return this.http.post(environment.apiUrl + 'Center/' + 'AddMyESchoolAssessmentGradewise?createby=' + createby + '&status=' + status, data)
  // }
  GetMyESchoolAssessmentGradewise(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMyESchoolAssessmentGradewise/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(where))
  }
  GetUpdateMyESchoolAssessmentGradwiseDetail(userid: string, role: string, pgid: string, schoolid: string, examtype: string, standard: string, batchid: string, year: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdateMyESchoolAssessmentGradwiseDetail/' + userid + '/' + role + '/' + pgid + '/' + schoolid + '/' + examtype + '/' + standard + '/' + batchid + '/' + year)
  }

  GetRptMyESchoolAssessmentSchoolwise(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyESchoolAssessmentSchoolwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptMyESchoolAssessmentStudentwise(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyESchoolAssessmentStudentwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptMyEschoolBaselinecompetency(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyEschoolBaselinecompetency/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptMyEschoolBaselinecompetencyMidline(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyEschoolBaselinecompetencyMidline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCenterTLDetail(userid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterTLDetail/' + userid);
  }
  GetRptMyEschoolBaselineSummary(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyEschoolBaselineSummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  Getassessmentsubjectwisesummary(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'Getassessmentsubjectwisesummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  VideoHelps(type: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'VideoHelps?type=' + type + '&pgid=' + pgid);
  }

  GetCALMidlineCompetencyStd5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlineCompetencyStd5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidlinecompetencyStd6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinecompetencyStd6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidlineCompetencyStd7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlineCompetencyStd7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidlineCompetencyStd8(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlineCompetencyStd8/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidlineCompetencyStd9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlineCompetencyStd9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidinecompetencyStd10(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidinecompetencyStd10/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALBaselineAssessmentLevelStudentsancharak(Userid: string, role: string, pgid: string, state: string, city: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselineAssessmentLevelStudentsancharak/' + Userid + '/' + role + '/' + pgid + '/' + state + '/' + city + '/' + exam);
  }

  GetPartnerplanvsactualSummary(whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPartnerplanvsactualSummary?whr=' + btoa(whr));
  }

  GetRptPlanvsActualAttedanceWeekwise(userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanvsActualAttedanceWeekwise/' + userid + '/' + role + '/' + pgid);
  }
  GetBatchList(data: any) {

    return this.http.post(environment.apiUrl + 'Center/' + 'GetBatchList', data)
  }

  GetIDIPlanVsActualAttendCCList(userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIPlanVsActualAttendCCList/' + userid + '/' + role + '/' + pgid);
  }
  GetIDIPlanVsActualAttDetailList(centid: string, batchid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIPlanVsActualAttDetailList/' + centid + '/' + batchid);
  }
  AddIDIPlanVSActualCCAttendance(centid: string, batchid: string, date: string, createby: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddIDIPlanVSActualCCAttendance?centid=' + centid + '&batchid=' + batchid + '&date=' + date + '&createby=' + createby, data);
  }
  GetIDIStudentAtteCCDetail(whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIStudentAtteCCDetail?whr=' + btoa(whr));
  }
  UpdatePlanVsActualAttCC(paccid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePlanVsActualAttCC/' + paccid, data);
  }
  GetIDIPlanVsActalStudentAtteCCDetail(paccid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIPlanVsActalStudentAtteCCDetail/' + paccid);
  }
  GetRptPlanVsActualCCAttendance(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanVsActualCCAttendance/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALBaselineAssessmentLevelStudent(Userid: string, role: string, pgid: string, state: string, city: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselineAssessmentLevelStudent/' + Userid + '/' + role + '/' + pgid + '/' + state + '/' + city + '/' + exam);
  }

  SendHardwareCallNotification(empid: string, callid: string, centername: string) {
    return this.http.get(environment.apiUrlAD + 'JankariPortalAdore/' + 'SendHardwareCallNotification/' + empid + '/' + callid + '?centname=' + btoa(centername));
  }
  GetCALAssessmentLevelStateGraphBaseline1to4(Userid: string, role: string, pgid: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStateGraphBaseline1to4/' + Userid + '/' + role + '/' + pgid + '/' + exam);
  }
  GetCALAssessmentLevelStateGraphBaseline5to12(Userid: string, role: string, pgid: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStateGraphBaseline5to12/' + Userid + '/' + role + '/' + pgid + '/' + exam);
  }
  GetCALAssessmentLevelCityGraphBaseline1to4(Userid: string, role: string, pgid: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelCityGraphBaseline1to4/' + Userid + '/' + role + '/' + pgid + '/' + exam);
  }

  GetCALAssessmentLevelCityGraphBaseline5to12(Userid: string, role: string, pgid: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelCityGraphBaseline5to12/' + Userid + '/' + role + '/' + pgid + '/' + exam);
  }
  GetCALAssessmentLevellanguageGraphBaseline1to4(Userid: string, role: string, pgid: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevellanguageGraphBaseline1to4/' + Userid + '/' + role + '/' + pgid + '/' + exam);
  }
  GetCALAssessmentLevellanguageGraphBaseline5to12(Userid: string, role: string, pgid: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevellanguageGraphBaseline5to12/' + Userid + '/' + role + '/' + pgid + '/' + exam);
  }
  GetCALAssessmentLevelStandardGraphBaseline1to4(Userid: string, role: string, pgid: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStandardGraphBaseline1to4/' + Userid + '/' + role + '/' + pgid + '/' + exam);
  }
  GetCALAssessmentLevelStandardGraphBaseline5to12(Userid: string, role: string, pgid: string, exam: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStandardGraphBaseline5to12/' + Userid + '/' + role + '/' + pgid + '/' + exam);
  }
  AssessmentComparisonListLange1to4(state: string, city: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'AssessmentComparisonListLange1to4/' + state + '/' + city);
  }

  AssessmentComparisonListMath1to4(state: string, city: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'AssessmentComparisonListMath1to4/' + state + '/' + city);
  }

  AssessmentComparisonListLange5to10(state: string, city: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'AssessmentComparisonListLange5to10/' + state + '/' + city);
  }
  GetCALAssessmentLevelStdGraphState1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphState1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraphState5to12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphState5to12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraphCity1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphCity1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraphCity5to12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphCity5to12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraphPartner1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphPartner1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraphPartner5to12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphPartner5to12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetActiveTimeTableAlert(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetActiveTimeTableAlert/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetApprovalTimeTableList(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetApprovalTimeTableList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  InactiveTimeTable(userid: string, userrole: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InactiveTimeTable/' + userid + '/' + userrole, data);
  }

  GetCALMidlinecompetencyStd1(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinecompetencyStd1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidlinecompetancyStd2(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinecompetancyStd2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidlinecompetancyStd3(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinecompetancyStd3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALMidlinecompetencyStd4(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinecompetencyStd4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  AddCALAserAssessment(schoolid: string, createby: string, examtype: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALAserAssessment/' + schoolid + '/' + createby + '/' + examtype, data);
  }
  AddEnglishAserAssessment(schoolid: string, createby: string, examtype: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddEnglishAserAssessment/' + schoolid + '/' + createby + '/' + examtype, data);
  }

  GetRptAserSchoolwise(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptAserSchoolwise/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetRptAserStudentwise(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptAserStudentwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  AddCALAserAssessment5(schoolid: string, standard: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALAserAssessment5/' + schoolid + '/' + standard + '/' + createby, data)
  }

  AddCALAserAssessment6(schoolid: string, standard: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALAserAssessment6/' + schoolid + '/' + standard + '/' + createby, data)
  }

  AddCALAserAssessment7(schoolid: string, standard: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALAserAssessment7/' + schoolid + '/' + standard + '/' + createby, data)
  }

  AddCALAserAssessment8(schoolid: string, standard: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALAserAssessment8/' + schoolid + '/' + standard + '/' + createby, data)
  }

  AddCALAserAssessment9(schoolid: string, standard: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALAserAssessment9/' + schoolid + '/' + standard + '/' + createby, data)
  }

  RemoveSchoolBatch(batchid: string, schoolid: string, standard: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveSchoolBatch/' + batchid + '/' + schoolid + '/' + standard);
  }

  GetInactiveCenterList(pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetInactiveCenterList/' + pgid);
  }

  GetInactiveSchools(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetInactiveSchools/' + centid);
  }

  SetInactiveSchoolsAndCenterToActive(centid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'SetInactiveSchoolsAndCenterToActive/' + centid, data);
  }

  GetCALEndlinecompetencyStd10(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyStd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALAserCompetencyStd5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAserCompetencyStd5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALAserCompetencyStd6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAserCompetencyStd6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALAserCompetencyStd7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAserCompetencyStd7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALAserCompetencyStd8(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAserCompetencyStd8/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALAserCompetencyStd9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAserCompetencyStd9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALAserCompetencyStd10(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAserCompetencyStd10/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALAserCompetencyStd11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAserCompetencyStd11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptIDIEvalautionCertificate(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIEvalautionCertificate/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptMyEschoolSummaryMidline(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyEschoolSummaryMidline/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinecompetencyStd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyStd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptPlanvsActualAttedanceStudentWeekwiseReport(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlanvsActualAttedanceStudentWeekwiseReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  DownloadCALPracticalAssessment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadCALPracticalAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALPracticalAssessmentList(userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentList/' + userid + '/' + role + '/' + pgid)
  }
  AddCALPracticalAssessment(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALPracticalAssessment/' + empid, data);
  }
  GetCALPracticalAssessmentDetail(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetail/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr))
  }
  GetCALEndlinecompetencyGujaratStd10(userid: String, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyGujaratStd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));

  }
  GetCALEndlinecompetencyGujaratStd12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyGujaratStd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentEndlineGujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentEndlineGujarat/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr))
  }
  DownloadCALAwarenessAssessment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadCALAwarenessAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadIDIAwarenessAssessment(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadIDIAwarenessAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddCALAwarenessAssessment(empid: string, examdays: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCALAwarenessAssessment/' + empid + '/' + examdays, data);
  }
  AddIDIAwarenessAssessment(empid: string, examdays: string, coursename: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddIDIAwarenessAssessment/' + empid + '/' + examdays + '/' + coursename, data);
  }
  GetDigitalAwarenessEmployeeList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetDigitalAwarenessEmployeeList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  InsertDigitalAwarenessEmployee(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertDigitalAwarenessEmployee/' + userid, data);
  }
  GetCALDigitalAwarenessAssessmentDetail(userid: string, role: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALDigitalAwarenessAssessmentDetail/' + userid + '/' + role);
  }
  GetIDIDigitalAwarenessAssessmentDetail(userid: string, role: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIDigitalAwarenessAssessmentDetail/' + userid + '/' + role);
  }
  GetDigitalAwarenessEmployeeDetail(daempid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetDigitalAwarenessEmployeeDetail/' + daempid);
  }
  UpdateDigitalAwarenessEmployeeDetail(daempid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateDigitalAwarenessEmployeeDetail/' + daempid + '?daempid=' + daempid, data);
  }
  DownloadCALPracticalAssessmentAllState(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadCALPracticalAssessmentAllState/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAserCompetencyStd12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAserCompetencyStd12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd1(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd1/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd2(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd2/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd3(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd3/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd8(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd8/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlinecompetencyStd11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyStd11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALSchoolAssessment1to4Endline(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALSchoolAssessment1to4Endline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetATALCompAssessmentEndline6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentEndline6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetATALCompAssessmentEndline7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentEndline7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetATALCompAssessmentEndline8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentEndline8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetATALCompAssessmentEndline9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentEndline9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetATALCompAssessmentEndline11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentEndline11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetCALEndlinePracticalcompetencyStd5(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd5/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd6(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd6/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd7(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd7/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd8(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd8/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd9(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd9/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }

  GetCALEndlinePracticalcompetencyStd5Gujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd5Gujarat/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd10Gujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd10Gujarat/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALPracticalAssessmentDetailstd5(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetailstd5/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALPracticalAssessmentDetailstd6(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetailstd6/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALPracticalAssessmentDetailstd7(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetailstd7/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALPracticalAssessmentDetailstd8(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetailstd8/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALPracticalAssessmentDetailstd9(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetailstd9/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALPracticalAssessmentDetailstd5Gujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetailstd5Gujarat/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd6Gujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd6Gujarat/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd7Gujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd7Gujarat/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd8Gujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd8Gujarat/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd9Gujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd9Gujarat/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd11Gujarat(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd11Gujarat/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetRptPracticalSchoolwise(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPracticalSchoolwise/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyKerala(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyKerala/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALPracticalAssessmentDetailstdKerala(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetailstdKerala/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd11Gujarat(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyStd11Gujarat/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd9Gujarat(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyStd9Gujarat/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd8Gujarat(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyStd8Gujarat/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd7Gujarat(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyStd7Gujarat/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd6Gujarat(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinecompetencyStd6Gujarat/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlineCompetencyStd5Gujarat(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlineCompetencyStd5Gujarat/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelStudentTheory(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentTheory/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelStudentTheoryBaseline(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentTheoryBaseline/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelSchool2022Aser(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelSchool2022Aser/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalcompetencyStd11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalcompetencyStd11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPracticalAssessmentDetailstd11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalAssessmentDetailstd11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelSchoolPractical(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelSchoolPractical/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelStudentPractical(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentPractical/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAttendanceLeaveFinancialYearWise(finyear: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAttendanceLeaveFinancialYearWise/' + finyear)
  }
  GetRptMyEschoolEndlineSummary(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyEschoolEndlineSummary/' + userid + '/' + role + '/' + pgid + '/' + '?whr=' + btoa(whr));
  }
  GetRptMyEschoolBaselinecompetencyEndline(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyEschoolBaselinecompetencyEndline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  AddCyberSmartAwarenessAssessment(createdby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddCyberSmartAwarenessAssessment/' + createdby, data);
  }
  DownloadCyberSmartStudent(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadCyberSmartStudent/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCyberSmartAwareness(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCyberSmartAwareness/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  InsertCyberSmartStudent(createby: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertCyberSmartStudent/' + createby, data);
  }
  GetCyberSmartStudentList(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCyberSmartStudentList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCyberSmartStudent(cybstid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCyberSmartStudent/' + cybstid);
  }
  UpdateCyberSmartStudent(cybstid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateCyberSmartStudent/' + cybstid, data);
  }
  GetATALRptPracticalSchoolwise(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALRptPracticalSchoolwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALPracticalAssessmentDetailstd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALPracticalAssessmentDetailstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALPracticalAssessmentDetailstd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALPracticalAssessmentDetailstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALPracticalAssessmentDetailstd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALPracticalAssessmentDetailstd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALPracticalAssessmentDetailstd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALPracticalAssessmentDetailstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALPracticalAssessmentDetailstd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALPracticalAssessmentDetailstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  DownloadPracticalAssessmentATAL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPracticalAssessmentATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  AddPracticalAssessmentATAL(empid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddPracticalAssessmentATAL/' + empid, data);
  }
  GetATALCompAssessmentBaseline6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentBaseline6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentBaseline7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentBaseline7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentBaseline8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentBaseline8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentBaseline9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentBaseline9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentBaseline10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentBaseline10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessment2022(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessment2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  DownloadBaselineAssessmentKerala(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadBaselineAssessmentKerala/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselineAssessmentDetailstd1(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailstd1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselineAssessmentDetailstd2(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailstd2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselineAssessmentDetailstd3(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailstd3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselineAssessmentDetailstd4(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailstd4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselineAssessmentDetailstd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailstd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselineAssessmentDetailstd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselineAssessmentDetailstd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  AddEvaluationKerala(empid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddEvaluationKerala/' + empid, data);
  }
  GetKeralaRptSchoolwise(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaRptSchoolwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselinecompetencyStd1(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencyStd1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselinecompetencyStd2(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencyStd2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselinecompetencyStd3(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencyStd3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselinecompetencyStd4(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencyStd4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselinecompetencyStd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencyStd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselinecompetencyStd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencyStd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaBaselinecompetencyStd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencyStd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailstd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailstd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailstd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailstd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailstd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailstd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailstd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailstd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyStd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyStd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyStd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyStd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyStd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyStd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyStd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyStd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyStd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyStd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyStd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyStd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALRptPracticalBaselineSchoolwise(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALRptPracticalBaselineSchoolwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  AddDLLSPracticalAssessment(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddDLLSPracticalAssessment/' + empid, data);
  }
  DownloadPracticalBaselineAssessmentCAL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPracticalBaselineAssessmentCAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyGujaratStdNew9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyGujaratStdNew9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyGujaratStdNew10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyGujaratStdNew10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyGujaratStdNew11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyGujaratStdNew11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyGujaratStdNew12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyGujaratStdNew12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyStdNew11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyStdNew11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyStdNew12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyStdNew12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyStdNew10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyStdNew10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyStdNew9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyStdNew9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyStdNew8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyStdNew8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyStdNew7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyStdNew7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyStdNew6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyStdNew6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetencyStdNew5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetencyStdNew5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQBaseline5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQBaseline6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQBaseline7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQBaseline8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQBaseline9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQBaseline10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyGujaratiStd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyGujaratiStd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyGujaratiStd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyGujaratiStd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyGujaratiStd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyGujaratiStd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyGujaratiStd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyGujaratiStd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyGujaratiStd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyGujaratiStd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyGujaratiStd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyGujaratiStd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyGujaratiStd11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyGujaratiStd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalBaselinecompetencyGujaratiStd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalBaselinecompetencyGujaratiStd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailGujaratistd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailGujaratistd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailGujaratistd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailGujaratistd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailGujaratistd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailGujaratistd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailGujaratistd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailGujaratistd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailGujaratistd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailGujaratistd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailGujaratistd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailGujaratistd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailGujaratistd11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailGujaratistd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessmentDetailGujaratistd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessmentDetailGujaratistd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentGujaratMCQBaseline9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQBaseline9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentGujaratMCQBaseline10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQBaseline10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentGujaratMCQBaseline11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQBaseline11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentGujaratMCQBaseline12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQBaseline12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptTlBillPayment(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptTlBillPayment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetTlPaymentList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTlPaymentList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetTLBillPaymentDetails(bpid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTLBillPaymentDetails/' + bpid)
  }
  InsertTLBillPayment(userid: string, data): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertTLBillPayment/' + userid, data);
  }
  UploadBillingImages(folder: string, imageName: string, data: FormData) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadBillingImages/' + folder + "/" + imageName, data);
  }
  GetRptIDIDigitalandFinancialLiteracyWomen(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIDigitalandFinancialLiteracyWomen/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptIDIDigitalLiteracyAdolescent(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIDigitalLiteracyAdolescent/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptIDIDigitaland21stCenturySkillsYouth(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIDigitaland21stCenturySkillsYouth/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  TLBillPaymentReceipt(bpid: string, month: string, year: string, refid: string, refname: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'TLBillPaymentReceipt/' + bpid + '/' + month + '/' + year + '/' + refid + '/' + refname)
  }
  GetRptCALAssessmentLevelStudentTheory2022(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentTheory2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetCALBaselineAssessmentLevelSchool2022(userid: string, role: string, pgid: string, examtype: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselineAssessmentLevelSchool2022/' + userid + '/' + role + '/' + pgid + '/' + examtype);
  }
  GetPIFRateDataDetails(type: string, creatid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFRateDataDetails/' + type + '/' + creatid);
  }
  GetPIFRateInfoDetails(type: string, creatid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFRateInfoDetails/' + type + '/' + creatid);
  }
  GetCenterRateReport(month: string, year: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterRateReport/' + month + '/' + year + '?whr=' + btoa(where))
  }
  InsertPlanTOTTraining(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertPlanTOTTraining/', data);
  }

  GetPlanTOTTrainingList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlanTOTTrainingList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  UpdatePlanTOTTraining(pretotid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePlanTOTTraining?pretotid=' + pretotid, data);
  }


  GetPlanTOTTrainingDetails(pretotid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlanTOTTrainingDetails/' + pretotid);
  }

  InsertActualTOTTraining(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertActualTOTTraining', data);
  }

  AddTOTTraninigPhoto(totid: string, userid: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'AddTOTTraninigPhoto/' + totid + '/' + userid, formData);
  }

  GetTOTTrainingPic(id: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTOTTrainingPic/' + id);
  }

  GetTOTTrainingDetails(totid: String): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTOTTrainingDetails/' + totid);
  }

  UpdateTOTTrainingDetail(totid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateTOTTrainingDetail?totid=' + totid, data);
  }

  RemoveTOTTraninigPhoto(id: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveTOTTraninigPhoto/' + id);
  }

  UpdateTOTTrainingFeedback(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateTOTTrainingFeedback/' + createby, data);
  }

  UpdateTOTTrainingAttendences(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateTOTTrainingAttendences/' + createby, data);
  }

  GetTOTTrainingFeedbacksDetail(totid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTOTTrainingFeedbacksDetail/' + totid);
  }

  GetTOTTrainingAttendenceSection(strid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTOTTrainingAttendenceSection/' + strid);
  }

  GetRptTOTTeacherTrainingSummary(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptTOTTeacherTrainingSummary/' + '?whr=' + btoa(where));
  }

  GetRptActualTOTTraining(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptActualTOTTraining/' + '?whr=' + btoa(where));
  }

  GetRptPrePlannedTOTTraining(whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPrePlannedTOTTraining/' + '?whr=' + btoa(whr));
  }

  GetCALTOTTrainingList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALTOTTrainingList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetTOTTrainingAttendencesDetail(totid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTOTTrainingAttendencesDetail/' + totid);
  }
  GetCALGradewiseAssessmentMCQBaseline11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQBaseline12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetGenerateBillConfirmationList(city: string, month: string, year: string, type: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetGenerateBillConfirmationList/' + city + '/' + month + '/' + year + '/' + type);
  }
  GenerateMonthlyBill(empid: string, reftype: string, month: string, year: string, billtype: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'GenerateMonthlyBill/' + empid + '/' + reftype + '/' + month + '/' + year + '/' + billtype, data);
  }
  GetCALAssessmentLevelStdGraphPartner5to12New(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphPartner5to12New/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishAssessmentPartnerwise(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishAssessmentPartnerwise/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishAssessmentStatewise(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishAssessmentStatewise/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishAssessmentCitywise(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishAssessmentCitywise/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishAssessmentPartnerwise2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishAssessmentPartnerwise2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishAssessmentStatewise2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishAssessmentStatewise2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishAssessmentCitywise2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishAssessmentCitywise2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelLanguagePartnerNew(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelLanguagePartnerNew/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraphJankariState5to12New(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphJankariState5to12New/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelLanguageStateNew(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelLanguageStateNew/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraphJankariCity5to12New(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphJankariCity5to12New/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALFinalAssessmentLevelLanguageCityNew(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALFinalAssessmentLevelLanguageCityNew/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALStateAssessmentGraphNew(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALStateAssessmentGraphNew/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALCityAssessmentGraphNew(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALCityAssessmentGraphNew/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALPartnerAssessmentGraphNEW(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPartnerAssessmentGraphNEW/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelSchool2022Practical2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelSchool2022Practical2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelStudentPractical2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentPractical2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelStudentPracticalBaseline2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentPracticalBaseline2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptIDIDigitalandFinancialLiteracyWomenBaseline(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIDigitalandFinancialLiteracyWomenBaseline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptIDIDigitalLiteracyAdolescentBaseline(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIDigitalLiteracyAdolescentBaseline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptIDIDigitaland21stCenturySkillsYouthBaseline(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIDigitaland21stCenturySkillsYouthBaseline/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GenerateBillManually(empid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'GenerateBillManually/' + empid, data);
  }
  GetCenterBillPaymentDetails(refid: string, reftype: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterBillPaymentDetails/' + refid + '/' + reftype);
  }
  GetPIFTlPaymentList(reftype: string, userid: string, pgid: string, role: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFTlPaymentList/' + reftype + '/' + userid + '/' + pgid + '/' + role + '?whr=' + btoa(where))
  }
  GetSchoolTLBillPaymentReport(userid: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolTLBillPaymentReport/' + userid + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCenterTLBillPaymentReport(userid: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterTLBillPaymentReport/' + userid + '/' + pgid + '?whr=' + btoa(where))
  }

  GetSchoolRateReport(month: string, year: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolRateReport/' + month + '/' + year + '?whr=' + btoa(where))
  }
  GetRptIDIDigitalandFinancialLiteracyWomenEntrepreneur(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptIDIDigitalandFinancialLiteracyWomenEntrepreneur/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetViewInvoiceData(month: string, year: string, billid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetViewInvoiceData/' + month + '/' + year + '/' + billid)
  }

  GetBillingMonthlySummary(month: string, year: string, userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetBillingMonthlySummaryNew/' + month + '/' + year + '/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentMidline6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentMidline6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentMidline7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentMidline7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentMidline8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentMidline8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentMidline9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentMidline9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetATALCompAssessmentMidline10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompAssessmentMidline10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  UpdateTLBillPayment(bpid: number, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateTLBillPayment/' + bpid, data);
  }
  GetPendingInvoiceYearly(refid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPendingInvoiceYearly/' + refid)
  }
  GetPendingInvoiceMonthly(refid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPendingInvoiceMonthly/' + refid)
  }
  AssignMonthlyInvoicePayment(userid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AssignMonthlyInvoicePayment/' + userid, data);
  }
  GetPaymentAssignReport(where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPaymentAssignReport' + '?whr=' + btoa(where))
  }
  GetBillListForMonth(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetBillListForMonth/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadPracticalMidlineAssessmentCAL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPracticalMidlineAssessmentCAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  AddDLLSPracticalAssessmentMidline(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddDLLSPracticalAssessmentMidline/' + empid, data);
  }
  GetCALGradewiseAssessmentMCQMidLine5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQMidLinecompetencyStd5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd8(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd8/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd10(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd10/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd1(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd2(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd3(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidLinecompetencyStd4(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidLinecompetencyStd4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALGradewiseAssessmentMCQMidLine1(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine2(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLin3(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLin3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentMCQMidLine4(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQMidLine4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  AddEvalutionkeralanew(empid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddEvalutionkeralanew/' + empid, data);
  }
  DownloadBaselineAssessmentKeralaNew(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadBaselineAssessmentKeralaNew/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  DownloadAserAssessment(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadAserAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  DownloadAserEnglishAssessment(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadAserEnglishAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALPracticalMidlinecompetency(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalMidlinecompetency/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailGujstd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailGujstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailGujstd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailGujstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailGujstd11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailGujstd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailGujstd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailGujstd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd5Gujarat(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd5Gujarat/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd6Gujarat(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd6Gujarat/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd7Gujarat(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd7Gujarat/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMidlinePracticalAssessmentDetailstd8Gujarat(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMidlinePracticalAssessmentDetailstd8Gujarat/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalMidlinecompetencyGujarat(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalMidlinecompetencyGujarat/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaEndlinecompetencyStd1(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencyStd1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaEndlinecompetencyStd2(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencyStd2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaEndlinecompetencyStd3(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencyStd3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaEndlinecompetencyStd4(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencyStd4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaEndlinecompetencyStd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencyStd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaEndlinecompetencyStd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencyStd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetKeralaEndlinecompetencyStd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencyStd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentGujaratMCQMidline9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQMidline9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentGujaratMCQMidline10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQMidline10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentGujaratMCQMidline11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQMidline11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALGradewiseAssessmentGujaratMCQMidline12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQMidline12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQMidlinecompetencyGujaratStdNew9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidlinecompetencyGujaratStdNew9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidlinecompetencyGujaratStdNew10(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidlinecompetencyGujaratStdNew10/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidlinecompetencyGujaratStdNew11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidlinecompetencyGujaratStdNew11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQMidlinecompetencyGujaratStdNew12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQMidlinecompetencyGujaratStdNew12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCompMidlineReport(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompMidlineReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetPartnerSancharakFeedback(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerSancharakFeedback?whr=' + btoa(where))
  }
  GetCALSancharakFeedback(userid: string, pgid: string, role: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetCALSancharakFeedback/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALLevelwiseComparison(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALLevelwiseComparison?whr=' + btoa(where))
  }
  GetPartnerStdStateWiseATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPartnerStdStateWiseATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGenderEnrollmentStateSummaryATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetGenderEnrollmentStateSummaryATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttStatePaiGraphATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttStatePaiGraphATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttedanceStateATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedanceStateATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALStatewiseCenterSchoolCountATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALStatewiseCenterSchoolCountATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALStateAssessmentGraphNewATL(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALStateAssessmentGraphNewATL/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetATALCitywiseCenterSchoolCount(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCitywiseCenterSchoolCount/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPartnerStdCityWiseATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPartnerStdCityWiseATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetGenderEnrollmentCitySummaryATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetGenderEnrollmentCitySummaryATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttedanceCityATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttedanceCityATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAttCityPaiGraphATAL(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAttCityPaiGraphATAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetIDIAwarenesSession(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDIAwarenesSession/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetIDILivelihoodCareerGuidance(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDILivelihoodCareerGuidance/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetIDILivelihoodJobPortal(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetIDILivelihoodJobPortal/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraph2022State1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraph2022State1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraph2022AserState1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraph2022AserState1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraph2022Partner1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraph2022Partner1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevel2022StdGraphAserCity1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevel2022StdGraphAserCity1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevel2022StdGraphCity1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevel2022StdGraphCity1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddLivelyhoodJobStudents(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddLivelyhoodJobStudents/' + userid, data)
  }
  AddLivelyhoodAwarenessSessionStudents(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddLivelyhoodAwarenessSessionStudents/' + userid, data)
  }
  LivelyhoodJobFilterStudents(centid: string, pbid: string, sessiondate) {
    return this.http.get(environment.apiUrl + 'Center/' + 'LivelyhoodJobFilterStudents/' + centid + '/' + pbid + '/' + sessiondate)
  }
  AddLivelyhoodPlacementStudents(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddLivelyhoodPlacementStudents/' + userid, data)
  }
  AddLivelihoodCareerGuidanceStudents(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddLivelihoodCareerGuidanceStudents/' + userid, data)
  }
  AddLivelihoodEnterpreneurship(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddLivelihoodEnterpreneurship/' + userid, data)
  }
  LivelihoodAwarenessPortalList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'LivelihoodAwarenessPortalList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetAwarenessSessionDetailList(lamid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAwarenessSessionDetailList/' + lamid)
  }
  UpdateAwarenessSessionDetails(empid, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateAwarenessSessionDetails/' + empid, data)
  }
  LivelihoodCareerGuidancePortalList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'LivelihoodCareerGuidancePortalList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetCareerGuidanceList(lcgmid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCareerGuidanceList/' + lcgmid)
  }
  UpdateCarrerGuidanceDetails(empid, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateCarrerGuidanceDetails/' + empid, data)
  }
  LivelihoodJobPortalList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'LivelihoodJobPortalList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetJoBPortalDetailList(ljmid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetJoBPortalDetailList/' + ljmid)
  }
  UpdateJobPortalDetails(empid, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateJobPortalDetails/' + empid, data)
  }
  LivelihoodPlacementPortalList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'LivelihoodPlacementPortalList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetPlacementList(lpmid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlacementList/' + lpmid)
  }
  UpdatePlacementDetails(empid, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePlacementDetails/' + empid, data)
  }
  LivelihoodEnterpreneurshipList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'LivelihoodEnterpreneurshipList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  UpdateEnterpreneurshipDetails(empid, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateEnterpreneurshipDetails/' + empid, data)
  }
  GetEntrepreneurshipList(lcgmid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEntrepreneurshipList/' + lcgmid)
  }
  GetAserComparison(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAserComparison/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAserBaselineEndlineNewComparision2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAserBaselineEndlineNewComparision2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALLevelwiseComparisonPractical(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALLevelwiseComparisonPractical?whr=' + btoa(where))
  }
  GetKeralaComperisionStd1(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd3(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd4(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd5(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd6(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd7(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd1sem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd1sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd2sem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd2sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd3sem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd3sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd4sem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd4sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd5sem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd5sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd6sem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd6sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd7sem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd7sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison3(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison3/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison4(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison4/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison5(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison6(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison7(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }










  AddEvaluationKeralaSem2(empid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddEvaluationKeralaSem2/' + empid, data);
  }
  GetRptCALAssessmentLevelStudentPracticalMidline2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentPracticalMidline2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaBaselineAssessmentDetailSem2std1(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailSem2std1/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselineAssessmentDetailSem2std2(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailSem2std2/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaBaselineAssessmentDetailSem2std3(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailSem2std3/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselineAssessmentDetailSem2std4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailSem2std4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselineAssessmentDetailSem2std5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailSem2std5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaBaselineAssessmentDetailSem2std6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailSem2std6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaBaselineAssessmentDetailSem2std7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselineAssessmentDetailSem2std7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselinecompetencySem2Std1(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencySem2Std1/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselinecompetencySem2Std2(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencySem2Std2/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselinecompetencySem2Std3(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencySem2Std3/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselinecompetencySem2Std4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencySem2Std4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselinecompetencySem2Std5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencySem2Std5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselinecompetencySem2Std6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencySem2Std6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKeralaBaselinecompetencySem2Std7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaBaselinecompetencySem2Std7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetPracticalTheoryBaselineMidlineComparision2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparision2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetPracticalTheoryBaselineMidlineComparisionStudentwise2022(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparisionStudentwise2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddPlannedMonthlyCenterVisit(empid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddPlannedMonthlyCenterVisit/' + empid, data);
  }
  GetPlannedMonthlyCenter(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlannedMonthlyCenter/' + month + '/' + year + '/' + '?whr=' + btoa(where));
  }
  GetMonthlyCenterVisitList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMonthlyCenterVisitList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  InsertMonthlyCenterVisit(data): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertMonthlyCenterVisit/', data);
  }
  UpdateMonthlyCenterVisit(mcvid, data): any {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateMonthlyCenterVisit/' + mcvid, data);
  }
  GetMonthlyCenterVisitUpdateList(mcvid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMonthlyCenterVisitUpdateList/' + mcvid);
  }
  RptPlanMonthlyCenterVisitRemark(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'RptPlanMonthlyCenterVisitRemark/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptMonthlyCenterVisit(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMonthlyCenterVisit/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptMonthlyCenterVisitSummary(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMonthlyCenterVisitSummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptPlannedMonthlyCenterVisit(userid: string, role: string, pgid: string, where, year1, year2): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPlannedMonthlyCenterVisit/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where) + '&year1=' + year1 + '&year2=' + year2);
  }


  // schoolteachertraining

  GetPlanSchoolTeacherTrainingList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlanSchoolTeacherTrainingList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSchoolTeacherTrainingList(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolTeacherTrainingList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  InsertPlanSchoolTeacherTraining(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertPlanSchoolTeacherTraining/', data);
  }
  InsertSchoolTeacherTraining(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertSchoolTeacherTraining/', data);
  }
  GetPlanSchoolTeacherTrainings(schoolteacherID: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPlanSchoolTeacherTrainings/' + schoolteacherID);
  }
  UpdatePlanSchoolTeacherTraining(plnschteachtranid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePlanSchoolTeacherTraining?plnschteachtranid=' + plnschteachtranid, data);
  }

  SchoolTeacherTrainingDetails(schteachtranid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'SchoolTeacherTrainingDetails/' + schteachtranid);
  }
  DownloadSchoolTeacherTrainingAtt(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadSchoolTeacherTrainingAtt?whr=' + btoa(where))
  }
  UpdateSchoolTeacherTrainingAttendences(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolTeacherTrainingAttendences/' + createby, data);
  }

  GetUpdateSchoolTeacherTrainingAttendences(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdateSchoolTeacherTrainingAttendences?whr=' + btoa(where))
  }
  UpdateSchoolTeacherTrainingFeedback(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolTeacherTrainingFeedback/' + createby, data);
  }
  AddSchoolTeacherTrainingsPhoto(schteachtranid: string, userid: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'AddSchoolTeacherTrainingsPhoto/' + schteachtranid + '/' + userid, formData);
  }
  GetTeacherTrainingsPic(id: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTeacherTrainingsPic/' + id);
  }
  RemoveTeacherTrainingsPhoto(id: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveTeacherTrainingsPhoto/' + id);
  }

  GetRptSchoolTeacherTraining(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSchoolTeacherTraining/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptSchoolTeacherSummaryTraining(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSchoolTeacherSummaryTraining/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptSchoolTeacherTrainingDetail(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSchoolTeacherTrainingDetail/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  UpdateSchoolTeacherTraining(sttid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolTeacherTraining?schteachtranid=' + sttid, data);
  }


  //principal workshop

  InsertPrincipalWorkshop(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertPrincipalWorkshop/', data);
  }
  GetPrincipalWorkshopList(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPrincipalWorkshopList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  PrincipalWorkshopDetails(ptrid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'PrincipalWorkshopDetails/' + ptrid);
  }
  DownloadPrincipalWorkshopTraining(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPrincipalWorkshopTraining?whr=' + btoa(where))
  }
  UpdatePrincipalWorkshopAttendance(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePrincipalWorkshopAttendance/' + createby, data);
  }
  GetPrincipalWorkshopAttendanceDetailsList(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPrincipalWorkshopAttendanceDetailsList?whr=' + btoa(where))
  }
  UpdatePrincipalWorkshop(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePrincipalWorkshop/', data);
  }

  UpdatePrincipalWorkshopFeedback(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePrincipalWorkshopFeedback/' + createby, data);
  }

  AddDLLSPracticalAssessmentEndline(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddDLLSPracticalAssessmentEndline/' + empid, data);
  }
  GetCALEndlinePracticalAssessmentDetailstd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailGujaratistd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailGujaratistd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinelinePracticalAssessmentDetailGujaratistd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinelinePracticalAssessmentDetailGujaratistd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalEndlinecompetencyStd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalEndlinecompetencyStd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalEndlinecompetencyGujaratiStd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalEndlinecompetencyGujaratiStd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalEndlinecompetencyGujaratiStd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalEndlinecompetencyGujaratiStd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  UpdateSchoolTeacherTrainingTest(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolTeacherTrainingTest/' + createby, data);
  }

  GetUpdateSchoolTeacherTrainingTest(schteachtranid: string, type: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdateSchoolTeacherTrainingTest/' + schteachtranid + '/' + type);
  }

  GetUpdateSchoolTeacherTrainingFeedback(schteachtranid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdateSchoolTeacherTrainingFeedback/' + schteachtranid)
  }


  GetUpdatePrincipalWorkshopFeedback(printrainid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdatePrincipalWorkshopFeedback/' + printrainid);
  }

  GetUpdatePrincipalWorkshopTest(ptrid: string, type: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdatePrincipalWorkshopTest/' + ptrid + '/' + type);
  }

  UpdatePrincipalWorkshopTest(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePrincipalWorkshopTest/' + createby, data);
  }

  GetBillingPaymentAssignReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetBillingPaymentAssignReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSchoolBillingPaymentAssignReport(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolBillingPaymentAssignReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptPrincipalWorkshop(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPrincipalWorkshop/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPrincipalWorkshopAttendanceList(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPrincipalWorkshopAttendanceList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadPrincipalWorkshopTest(ptrid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPrincipalWorkshopTest/' + ptrid)
  }
  DownloadSchoolTeacherTrainingTest(schteachtranid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadSchoolTeacherTrainingTest/' + schteachtranid)
  }
  GetCenterGeneratedInvoices(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterGeneratedInvoices/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSchoolGeneratedInvoices(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolGeneratedInvoices/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPrincipalWorkshopTestReport(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPrincipalWorkshopTestReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALEndlinePracticalAssessmentDetailGujaratistd11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailGujaratistd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalEndlinecompetencyGujaratiStd11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalEndlinecompetencyGujaratiStd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  DownloadSchoolTeacherTrainingPostTest(schteachtranid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadSchoolTeacherTrainingPostTest/' + schteachtranid)
  }
  GetCALEndlinePracticalAssessmentDetailstd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailstd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailGujaratistd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailGujaratistd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailstd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailGujaratistd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailGujaratistd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailstd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailGujaratistd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailGujaratistd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailstd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailstd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailGujaratistd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailGujaratistd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailstd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALEndlinePracticalAssessmentDetailGujaratistd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALEndlinePracticalAssessmentDetailGujaratistd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalEndlinecompetencyStd5to12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalEndlinecompetencyStd5to12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalEndlinecompetencyGujaratiStd5to12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalEndlinecompetencyGujaratiStd5to12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptCALAssessmentLevelStudentPracticalEndline2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentPracticalEndline2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }


  //computerexibitionlist
  InsertComputerList(data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertComputerList/', data);
  }
  GetComputerExhibitionDetails(compexid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetComputerExhibitionDetails/' + compexid)
  }
  updateexhibitionlist(compexid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'updateexhibitionlist/' + compexid, data);
  }
  GetCheifGuestlist(compexid) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCheifGuestlist/' + compexid);
  }
  AddGuestdetail(body: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddGuestdetail/', body);
  }
  Removeguest(chifid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'Removeguest/' + chifid);
  }
  //photo
  AddComputerExhibitionPhoto(compexid: string, userid: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'AddComputerExhibitionPhoto/' + compexid + '/' + userid, formData);
  }
  GetComputerExhibitionPic(id: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetComputerExhibitionPic/' + id);
  }
  RemoveComputerExhibitionPhoto(id: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveComputerExhibitionPhoto/' + id);
  }
  GetComputerexhibitionList(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetComputerexhibitionList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALStateAssessmentGraphSchool(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALStateAssessmentGraphSchool/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraph2022School1to4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraph2022School1to4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALAssessmentLevelStdGraphSchool5to12New(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphSchool5to12New/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineMidlineComparisionStudent2022(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineMidlineComparisionStudent2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineEndlineComparisionStudent2022(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineEndlineComparisionStudent2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineMidlineComparisionStudent2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparisionStudent2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineEndlineComparision2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparision2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLevelBaselineEndlineComparision2023KeralaSem1(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLevelBaselineEndlineComparision2023KeralaSem1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineEndlineComparisionStudent2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparisionStudent2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLevelBaselineEndlineComparisionStudent2023KeralaSem1(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLevelBaselineEndlineComparisionStudent2023KeralaSem1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  DownloadPracticalEndlineAssessmentCAL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPracticalEndlineAssessmentCAL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }


  //Endline 2022-2023


  GetCALGradewiseAssessmentGujaratMCQEndline10(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQEndline10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentGujaratMCQEndline12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentGujaratMCQEndline12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }


  GetCALMCQEndlinecompetencyStdNew5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyStdNew5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyStdNew6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyStdNew6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyStdNew7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyStdNew7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyStdNew8(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyStdNew8/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyStdNew9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyStdNew9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyStdNew10(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyStdNew10/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyStdNew11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyStdNew11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyStdNew12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyStdNew12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndline5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndline5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndline6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndline6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndline7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndline7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndline8(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndline8/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndline9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndline9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetCALGradewiseAssessmentMCQEndline10(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndline10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndline11(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndline11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndline12(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndline12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }


  //gujrat

  GetCALMCQEndlinecompetencyGujaratStdNew9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyGujaratStdNew9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyGujaratStdNew10(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyGujaratStdNew10/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyGujaratStdNew11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyGujaratStdNew11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALMCQEndlinecompetencyGujaratStdNew12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQEndlinecompetencyGujaratStdNew12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndlineGujaratiSTD9(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndlineGujaratiSTD9/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndlineGujaratiSTD10(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndlineGujaratiSTD10/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndlineGujaratiSTD11(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndlineGujaratiSTD11/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQEndlineGujaratiSTD12(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQEndlineGujaratiSTD12/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetSchoolTeacherTrainingTestReport(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolTeacherTrainingTestReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetSchoolTeacherTrainingAttendenceList(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolTeacherTrainingAttendenceList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetPIFCollectionSchool(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFCollectionSchool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetPIFCollectionCenter(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPIFCollectionCenter/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  DownloadEnglishBaselineAssessment(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadEnglishBaselineAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  EngAssessment(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'EngAssessment/' + empid, data);
  }
  GetRptAttendanceStudWiseCALDetail(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptAttendanceStudWiseCALDetail/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetEnglishBaselineAssessmentDetailstd5(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishBaselineAssessmentDetailstd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishBaselineAssessmentDetailstd6(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishBaselineAssessmentDetailstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishBaselineAssessmentDetailstd7(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishBaselineAssessmentDetailstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishBaselineAssessmentDetailstd8(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishBaselineAssessmentDetailstd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishBaselineAssessmentDetailstd9(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishBaselineAssessmentDetailstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishBaselineAssessmentDetailstd11(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishBaselineAssessmentDetailstd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishBaselineAssessmentDetailstd10(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishBaselineAssessmentDetailstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishBaselineAssessmentDetailstd12(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishBaselineAssessmentDetailstd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCALAssessmentLevelSchool2022Mcq(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelSchool2022Mcq/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  // Atal student competency summary baseline 

  GetATALCompBaselineReport(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetATALCompBaselineReport/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  DownloadEnglishEndlineAssessment(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadEnglishEndlineAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptCALAssessmentLevelStudentTheoryEndline2022(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentTheoryEndline2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetKeralaEndlinecompetencySem2Std1(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencySem2Std1/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaEndlinecompetencySem2Std2(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencySem2Std2/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaEndlinecompetencySem2Std3(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencySem2Std3/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaEndlinecompetencySem2Std4(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencySem2Std4/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaEndlinecompetencySem2Std5(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencySem2Std5/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaEndlinecompetencySem2Std6(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencySem2Std6/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaEndlinecompetencySem2Std7(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaEndlinecompetencySem2Std7/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  InsertTOTTrainingFeedback2023(userid: string, data: any,) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertTOTTrainingFeedback2023/' + userid, data);
  }
  InsertSancharakTrainingFeedback2023(userid: string, data: any,) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertSancharakTrainingFeedback2023/' + userid, data);
  }

  DownloadMyESchoolAssessmentGradewise(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadMyESchoolAssessmentGradewise/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  AddMyESchoolAssessmentGradewise(schoolid: string, createby: string, examtype: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddMyESchoolAssessmentGradewise/' + schoolid + '/' + createby + '/' + examtype, data);
  }
  GetTOTTrainingPartnerWiseFeedback2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTOTTrainingPartnerWiseFeedback2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetTOTTrainingPartnerWiseFeedbackUserwise2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetTOTTrainingPartnerWiseFeedbackUserwise2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineMidlineComparisionjankari2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparisionjankari2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineMidlineComparisionjankari2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparisionjankari2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineMidlineComparisionPartner2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineMidlineComparisionPartner2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineMidlineComparisionPartner2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineMidlineComparisionPartner2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineEndlineNewComparisionPartner2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineEndlineNewComparisionPartner2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineEndlineComparisionJankari2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparisionJankari2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineMidlineComparisionState2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparisionState2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineMidlineComparisionState2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparisionState2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineEndlineComparisionState2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparisionState2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineMidlineComparisionState2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineMidlineComparisionState2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineMidlineComparisionState2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineMidlineComparisionState2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineEndlineNewComparisionState2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineEndlineNewComparisionState2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineMidlineComparisionCity2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparisionCity2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineMidlineComparisionCity2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineMidlineComparisionCity2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineEndlineComparisionCity2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparisionCity2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineMidlineComparisionCity2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineMidlineComparisionCity2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineMidlineComparisionCity2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineMidlineComparisionCity2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineEndlineNewComparisionCity2022(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineEndlineNewComparisionCity2022/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishAssessmentSchoolwise(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishAssessmentSchoolwise/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetSancharakTraineeAttendanceDetails(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSancharakTraineeAttendanceDetails/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetSancharakTraineeAttendanceDetailsUserwise(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSancharakTraineeAttendanceDetailsUserwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetStudentAttendanceAlertNB2022(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStudentAttendanceAlertNB2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetStudentAttendanceAlertPendingNB2022(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStudentAttendanceAlertPendingNB2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetStudentAttendanceAlertNBDetails2022(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStudentAttendanceAlertNBDetails2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }


  // baseline 2023-2024
  GetCALBaselinePracticalAssessment2023std5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023std5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023std6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023std6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023std7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023std7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023std8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023std8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023std9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023std9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023std10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023std10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023Gujaratstd5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023Gujaratstd5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023Gujaratstd6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023Gujaratstd6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023Gujaratstd7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023Gujaratstd7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023Gujaratstd8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023Gujaratstd8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023Gujaratstd9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023Gujaratstd9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023Gujaratstd10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023Gujaratstd10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023Gujaratstd11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023Gujaratstd11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALBaselinePracticalAssessment2023Gujaratstd12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBaselinePracticalAssessment2023Gujaratstd12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  // mcq theory 2023-2024

  GetCALGradewiseAssessmentMCQBaseline5std2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline5std2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline6std2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline6std2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline7std2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline7std2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline8std2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline8std2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline9std2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline9std2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline10std2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline10std2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline11std2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline11std2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline12std2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline12std2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline9stdGujarat2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline9stdGujarat2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline10stdGujarat2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline10stdGujarat2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline11stdGujarat2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline11stdGujarat2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradewiseAssessmentMCQBaseline12stdGujarat2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradewiseAssessmentMCQBaseline12stdGujarat2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptCALAssessmentLevelStudentTheory2023(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentTheory2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  AddDLLSPracticalAssessmentBaseline2023(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddDLLSPracticalAssessmentBaseline2023/' + empid, data);
  }
  DownloadPracticalBaselineAssessmentCAL2023(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPracticalBaselineAssessmentCAL2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALMCQBaselinecompetency2023Std5(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetency2023Std5/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQBaselinecompetency2023Std6(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetency2023Std6/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQBaselinecompetency2023Std7(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetency2023Std7/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQBaselinecompetency2023Std8(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetency2023Std8/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQBaselinecompetency2023Std9(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetency2023Std9/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQBaselinecompetency2023Std10(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetency2023Std10/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQBaselinecompetency2023Std11(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetency2023Std11/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetCALMCQBaselinecompetency2023Std12(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALMCQBaselinecompetency2023Std12/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptAserEnglishStudentwise(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptAserEnglishStudentwise/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  EngAssessmentl2(UserId: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'EngAssessmentl2/' + UserId, data);
  }
  InsertActionPointer(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertActionPointer', data);
  }
  GetActionPointer(fid: any) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetActionPointer/' + fid);
  }
  AddGameKraftPracticalAssessment(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddGameKraftPracticalAssessment/' + empid, data);
  }
  DownloadPracticalGameKraft2023(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPracticalGameKraft2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetStateWiseAllSummaryEnglish(pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStateWiseAllSummaryEnglish/' + pgid);
  }
  GetStateWiseAllSummaryMindspark(pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStateWiseAllSummaryMindspark/' + pgid);
  }
  GetEnglishCompentancyStudentwiselevel2report(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishCompentancyStudentwiselevel2report/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishCompentancyStudentwiselevel1report(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishCompentancyStudentwiselevel1report/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishCompentancySchoolwiselevel1report(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishCompentancySchoolwiselevel1report/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetEnglishCompentancySchoolwiselevel2report(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetEnglishCompentancySchoolwiselevel2report/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptAttendanceNBPending(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptAttendanceNBPending/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetCALGradeWisePracticalGameKraft(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALGradeWisePracticalGameKraft/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetCALPracticalGameKraft(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALPracticalGameKraft/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  UpdateActionPointer(actionid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateActionPointer/' + actionid, data);
  }
  GetPracticalGamekraftWebL1(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalGamekraftWebL1/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetPracticalGamekraftWebL2(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalGamekraftWebL2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }

  GetPracticalGamekraftSoftware(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalGamekraftSoftware/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetPracticalGamekraftApp(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalGamekraftApp/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  GetRptCALAssessmentLevelStudentTheoryEndline2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCALAssessmentLevelStudentTheoryEndline2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetStudentAttendanceAlertNB2022English(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStudentAttendanceAlertNB2022English/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetStudentAttendanceAlertPendingNBEnglesh2022(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetStudentAttendanceAlertPendingNBEnglesh2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  InsertCenterInfraMyEschool(centid: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertCenterInfraMyEschool/' + centid + '/' + userid, data);
  }
  GetCenterInfraMyEschool(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterInfraMyEschool/' + centid);
  }
  DownloadMESCenterInfra(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadMESCenterInfra/' + centid);
  }
  InsertMyEschoolTeacher(createdby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertMyEschoolTeacher/' + createdby, data);
  }
  GetMyEschoolTeacher(mytechid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMyEschoolTeacher/' + mytechid);
  }
  UpdateMyEschoolTeacher(mytechid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateMyEschoolTeacher/' + mytechid, data);
  }
  GetRptMyEschoolTeacherTraining(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMyEschoolTeacherTraining/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  AddMyESchoolTeacherAttendance(userid: string, cluster: string, date: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddMyESchoolTeacherAttendance/' + userid + '?cluster=' + cluster + '&date=' + date, data);
  }
  GetMyEschoolTeacherTrainingAtt(mytechattid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMyEschoolTeacherTrainingAtt/' + mytechattid);
  }
  GetMyEschoolTeacherClusterList(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMyEschoolTeacherClusterList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  UpdateMyEschoolTeacherTrainingAtt(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateMyEschoolTeacherTrainingAtt/' + userid, data);
  }
  AddDLLSPracticalAssessmentMidline2024(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddDLLSPracticalAssessmentMidline2024/' + userid, data)
  }
  CenterTypeliteList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'CenterTypeliteList/' + centid);
  }
  GetCenterPartnerLiteList(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterPartnerLiteList/' + centid);
  }
  GetCenterLiteDetails(centid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCenterLiteDetails/' + centid);
  }
  GetSchoolLiteEnrollment(schoolid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolLiteEnrollment/' + schoolid);
  }
  GetSchoolLite(schoolid: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolLite/' + schoolid + '/' + pgid);
  }
  CenterLiteList(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'CenterLiteList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  SchoolLiteList(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'SchoolLiteList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  DownloadEnglishMidlineAssessment(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadEnglishMidlineAssessment/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  EngAssessmentMidline(UserId: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'EngAssessmentMidline/' + UserId, data);
  }
  AddEvaluationKeralaSem2Baseline(empid: string, data) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddEvaluationKeralaSem2Baseline/' + empid, data);
  }
  AddFoundationAssessmentBaselineMCQ(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddFoundationAssessmentBaselineMCQ/' + userid, data);
  }
  AddFoundationPracticalAssessmentBaseline(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddFoundationPracticalAssessmentBaseline/' + userid, data);
  }
  GetKeralaComperisionStd12023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd12023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd22023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd22023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd32023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd32023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd42023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd42023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd52023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd52023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd62023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd62023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperisionStd72023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperisionStd72023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetKerlaStudentComparison7std2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison7std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison6std2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison6std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison5td2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison5td2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison4std2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison4std2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison3rd2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison3rd2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison2nd2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison2nd2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKerlaStudentComparison1st2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison1st2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  InsertFoundationTrainingFeedback2023(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertFoundationTrainingFeedback2023/' + userid, data);
  }
  DownloadPracticalEndlineAssessmentCAL2023(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPracticalEndlineAssessmentCAL2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where))
  }
  AddDLLSPracticalAssessmentEndline2023(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddDLLSPracticalAssessmentEndline2023/' + empid, data);
  }
  DownloadSchoolTeacherTrainingTest2023(schteachtranid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadSchoolTeacherTrainingTest2023/' + schteachtranid)
  }

  DownloadSchoolTeacherTrainingPostTest2023(schteachtranid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadSchoolTeacherTrainingPostTest2023/' + schteachtranid)
  }

  UpdateSchoolTeacherTrainingTest2023(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolTeacherTrainingTest2023/' + createby, data);
  }

  GetUpdateSchoolTeacherTrainingTest2023(schteachtranid: string, type: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdateSchoolTeacherTrainingTest2023/' + schteachtranid + '/' + type);
  }

  GetUpdateSchoolTeacherTrainingFeedback2023(schteachtranid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdateSchoolTeacherTrainingFeedback2023/' + schteachtranid)
  }

  UpdateSchoolTeacherTrainingFeedback2023(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolTeacherTrainingFeedback2023/' + createby, data);
  }
  PrincipalWorkshopDetails2023(ptrid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'PrincipalWorkshopDetails2023/' + ptrid);
  }

  DownloadPrincipalWorkshop2023Training(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPrincipalWorkshop2023Training?whr=' + btoa(where))
  }

  DownloadPrincipalWorkshopTest2023(ptrid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPrincipalWorkshopTest2023/' + ptrid)
  }

  GetPrincipalWorkshop2023Attendance2023DetailsList(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPrincipalWorkshop2023Attendance2023DetailsList?whr=' + btoa(where))
  }

  GetPrincipalWorkshopList2023(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPrincipalWorkshopList2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  UpdatePrincipalWorkshopAttendance2023(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePrincipalWorkshopAttendance2023/' + createby, data);
  }


  GetUpdatePrincipalWorkshopTest2023(ptrid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdatePrincipalWorkshopTest2023/' + ptrid);
  }

  UpdatePrincipalWorkshopTest2023(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePrincipalWorkshopTest2023/' + createby, data);
  }

  GetUpdatePrincipalWorkshopFeedback2023(printrainid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetUpdatePrincipalWorkshopFeedback2023/' + printrainid);
  }

  UpdatePrincipalWorkshopFeedback2023(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePrincipalWorkshopFeedback2023/' + createby, data);
  }

  UpdatePrincipalWorkshop2023(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdatePrincipalWorkshop2023/', data);
  }
  InsertPrincipalWorkshop2023(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertPrincipalWorkshop2023/', data);
  }
  GetRptSchoolTeacherTrainingDetail2023(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSchoolTeacherTrainingDetail2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  SchoolTeacherTrainingDetails2023(schteachtranid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'SchoolTeacherTrainingDetails2023/' + schteachtranid);
  }

  InsertSchoolTeacherTraining2023(data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'InsertSchoolTeacherTraining2023/', data);
  }

  GetSchoolTeacherTrainingList2023(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolTeacherTrainingList2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  DownloadschoolteachertrainingAtt2023(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadschoolteachertrainingAtt2023?whr=' + btoa(where))
  }

  UpdateSchoolTeacherTrainingAttendence2023(createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolTeacherTrainingAttendence2023/' + createby, data);
  }

  GetRptSchoolTeacherSummaryTraining2023(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptSchoolTeacherSummaryTraining2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  DownloadPrincipalWorkshopFeedback2023(printrainid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'DownloadPrincipalWorkshopFeedback2023/' + printrainid);
  }

  GetRptPrincipalWorkshop2023(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptPrincipalWorkshop2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetPrincipalWorkshopAttendanceList2023(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPrincipalWorkshopAttendanceList2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptFoundationAssessmentLevelSchool2022Practical2022(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptFoundationAssessmentLevelSchool2022Practical2022/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetRptFoundationAssessmentLevelStudentPracticalBaseline2023(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptFoundationAssessmentLevelStudentPracticalBaseline2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPartnerStdSchoolWise(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStdSchoolWise?whr=' + btoa(where));
  }

  GetMyESchoolAserPartnerGraph(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetMyESchoolAserPartnerGraph?whr=' + btoa(where));
  }

  GetPracticalTheoryBaselineEndlineComparisionStudent2023GameKraft(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparisionStudent2023GameKraft/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineEndlineComparision2023Gamekraft(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparision2023Gamekraft/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  AddFoundationPracticalAssessmentEndline(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddFoundationPracticalAssessmentEndline/' + userid, data);
  }
  AddFoundationAssessmentEndlineMCQ(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddFoundationAssessmentEndlineMCQ/' + userid, data);
  }
  GetRptMonthlyOfficeVisit(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMonthlyOfficeVisit/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptMonthlyOfficeVisitAssociate(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptMonthlyOfficeVisitAssociate/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  getDigitalClassroomCount(schoolid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'getDigitalClassroomCount/' + schoolid);
  }
  GetRptCenterLiteAgreement(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCenterLiteAgreement?whr=' + btoa(where));
  }
  GetLevelBaselineEndlineComparision2023KeralaSem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLevelBaselineEndlineComparision2023KeralaSem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetLevelBaselineEndlineComparisionStudent2023KeralaSem2(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetLevelBaselineEndlineComparisionStudent2023KeralaSem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetKeralaComperision2023Std1sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperision2023Std1sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKeralaComperision2023Std2sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperision2023Std2sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKeralaComperision2023Std3sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperision2023Std3sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKeralaComperision2023Std4sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperision2023Std4sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKeralaComperision2023Std5sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperision2023Std5sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKeralaComperision2023Std6sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperision2023Std6sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKeralaComperision2023Std7sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKeralaComperision2023Std7sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKerlaStudentComparison1st2023sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison1st2023sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKerlaStudentComparison2nd2023sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison2nd2023sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKerlaStudentComparison3rd2023sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison3rd2023sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKerlaStudentComparison4std2023sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison4std2023sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKerlaStudentComparison5td2023sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison5td2023sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKerlaStudentComparison6std2023sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison6std2023sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetKerlaStudentComparison7std2023sem2(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetKerlaStudentComparison7std2023sem2/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetPracticalTheoryBaselineEndlineComparisionState2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparisionState2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetPracticalTheoryBaselineEndlineComparisionCity2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparisionCity2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetAsetBaselineEndlineNewComparisionState2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineEndlineNewComparisionState2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  GetAsetBaselineEndlineNewComparisionCity2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineEndlineNewComparisionCity2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetPracticalTheoryBaselineEndlineComparisionJankari2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPracticalTheoryBaselineEndlineComparisionJankari2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetAsetBaselineEndlineNewComparisionPartner2023(Userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetAsetBaselineEndlineNewComparisionPartner2023/' + Userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }
  GetRptCenterForAccounts(userid: string, role: string, pgid: string, whr): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetRptCenterForAccounts/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

}
