import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CenterService } from 'src/app/shared/services/center.service';

@Component({
  selector: 'app-batchwiseattdetailspopup',
  templateUrl: './batchwiseattdetailspopup.component.html',
  styleUrls: ['./batchwiseattdetailspopup.component.scss']
})
export class BatchwiseattdetailspopupComponent implements OnInit {
  batchdatalist
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: CenterService) { 
    console.log(data);
    this.service.GetBatchList(data).subscribe((attdata:any)=>{
      attdata.sort((a, b) => (a.standard - b.standard));
      console.log(attdata);
      this.batchdatalist = attdata
    })
  }

  ngOnInit() {
  }

}
