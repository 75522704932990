import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';

@Component({
  selector: 'app-loanpopup',
  templateUrl: './loanpopup.component.html',
  styleUrls: ['./loanpopup.component.scss']
})
export class LoanpopupComponent implements OnInit {
  loandata;
  amount;
  description;
  approvename;
  tenuaremonth;
  installment;
  approvedate;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any , public dialog: MatDialog, private service: MasterServiceService) {

    service.GetLoanDetail(data.id).subscribe((data: any) => {
      this.loandata = data;
this.amount= data.Reqloanamount;
this.description= data.description;
this.approvename= data.approvename;
this.tenuaremonth= data.tenuaremonth;
this.installment= data.installment;
this.approvedate= data.approvedate;
     
     
    });
   }

  ngOnInit() {
  }

  clear() {

     this.dialog.ngOnDestroy();
    }
}
