import { Component, OnInit, Inject } from '@angular/core';
import { EmployeeProgram } from 'src/app/ClassFiles/employee-program';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { StudentService } from 'src/app/shared/services/student.service';
import { ActivatedRoute } from '@angular/router';
import { CenterService } from 'src/app/shared/services/center.service';

@Component({
  selector: 'app-editstatuspopup',
  templateUrl: './editstatuspopup.component.html',
  styleUrls: ['./editstatuspopup.component.scss']
})
export class EditstatuspopupComponent implements OnInit {
  EmpProgramForm: FormGroup;
  reportsto;
  Designationlist;
  empid;
  id;
  depid;
  EmployeeProgram;
  desid;
  UserId;
  pgname;
  Userrole;
  centerid;
  pgid;
  idistudentid;
  studcourseid;
  studentid;
  Coursedpd;
  batchdpd;
  centid;
  hidebutton;
  batchdate;
  sbatch;


  constructor(private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private services: StudentService, private toastr: ToastrService, private service: MasterServiceService, private fb: FormBuilder,private centerservice: CenterService) {

    this.studentid = data.studentid;
    this.studcourseid = data.id;
    this.centid = data.centid;

    this.UserId = localStorage.getItem('UserId');
    this.pgname = localStorage.getItem('pgname');
    this.Userrole = localStorage.getItem('role');

    this.pgid = localStorage.getItem('pgid');
    this.service.FillDropDown('IDIBatch', 'distinct batchname', 'batchid', 'where IDIBatch.status=0 and IDIBatch.centid=\'' + this.centid + '\'').subscribe(
      (data: any) => {
        this.batchdpd = data;
     
        
      });
    services.GetStudentCourse('where Studentcourse.studentid=\'' + this.studentid + '\' and  Studentcourse.studcourseid=\'' + this.studcourseid + '\'').subscribe((data: any) => {
      this.EmployeeProgram = data;

    });



    this.service.FillDropDown('Course', 'distinct coursename', 'courseid', 'inner join CenterCourse on Course.courseid=CenterCourse.courseiid where CenterCourse.centid=\'' + this.centid + '\'').subscribe(
      (data: any) => {
        this.Coursedpd = data;

      });

  }

  ngOnInit() {
    this.EmpProgramForm = this.fb.group({
      status: [''],
      batchname: ['', Validators.required],
      coursename: ['', Validators.required],
    });
  }
  clear() {

    this.dialog.ngOnDestroy();
  }


  OnselectBatch(batchname) {
   
  


    this.centerservice.GetCenterParentbatchDetail(this.centid, batchname).subscribe((data: any) => {

    
      var newstartdate = data[0].startdate;
      var currdate = data[0].currentdate;
   
      var date1 = new Date(newstartdate);
  
      var date2 = new Date(currdate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if (Difference_In_Days > 60) {
        this.hidebutton = true;
      }
      else {
        this.hidebutton = false;

      }

    })

  }


  Update() {
    const Empprogram: EmployeeProgram = new EmployeeProgram();
    Empprogram.status = this.EmpProgramForm.value.status;
    Empprogram.courseid = this.EmpProgramForm.value.coursename;
    Empprogram.batchid = this.EmpProgramForm.value.batchname;

    Empprogram.updateby = this.UserId;

    this.services.UpdateStudentCourse(this.studentid, this.studcourseid, Empprogram).subscribe((res) => {

      this.toastr.success('Update SuccessFully');
      this.dialog.ngOnDestroy();
      window.location.reload();
    },
      error => {
        console.log(error);
      }
    );
  }
}