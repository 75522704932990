import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-empprogramassigncenterpopup',
  templateUrl: './empprogramassigncenterpopup.component.html',
  styleUrls: ['./empprogramassigncenterpopup.component.scss']
})
export class EmpprogramassigncenterpopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,private fb: FormBuilder,public dialog: MatDialog, private service: MasterServiceService,private toastr: ToastrService) { 
    console.log(data);
    
  }

  ngOnInit() {
    
  }
  clear() {

    this.dialog.ngOnDestroy();
   }
}
