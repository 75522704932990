import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CenterService } from 'src/app/shared/services/center.service';

export class actionpointer {
  public dateofcompletion: Date = new Date();
  public status: string = '';
  public updatedby: string = '';
  public actiontaken: string = '';
  public responsibleperson: string = '';
}

@Component({
  selector: 'app-updateactionpointerpopup',
  templateUrl: './updateactionpointerpopup.component.html',
  styleUrls: ['./updateactionpointerpopup.component.scss']
})

export class UpdateactionpointerpopupComponent implements OnInit {
  UserId;
  actionid;
  actiontaken;
  resperson;
  timeline;
  ActiveForm: FormGroup;
  loading;
  Updatedby;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: MasterServiceService, public dialog: MatDialog, private fb: FormBuilder, private toastr: ToastrService, private router: Router, private centserivce: CenterService) {
    this.UserId = localStorage.getItem("UserId");
    this.actionid = data.actionid;
    this.actiontaken = data.actiontaken;
    this.timeline = data.timelineforactionpointer;
    this.resperson = data.responsibleperson;
  }

  ngOnInit() {
    this.ActiveForm = this.fb.group({
      status: ['', Validators.required],
      doc: ['', Validators.required],
    })
  }

  ChangeStatus() {
    this.loading = true;
    const data: actionpointer = new actionpointer();
    data.status = this.ActiveForm.value.status;
    data.dateofcompletion = this.ActiveForm.value.doc;
    data.updatedby = this.UserId;
    data.actiontaken = this.actiontaken;
    data.responsibleperson = this.resperson;
    
    this.centserivce.UpdateActionPointer(this.actionid, data).subscribe((res: string) => {
      console.log(res);
      
      if (res == 'Success') {
        this.loading = false;
        this.dialog.ngOnDestroy();
        this.toastr.success('Changes Updated Sucessfully!')
        // this.router.navigate(['VisitFeedbackPage?Vlid']);
        window.location.reload();
        // this.dialog.ngOnDestroy();
        // this.ngOnDestroy.close()
      }
      else {
        this.loading = false;
        this.dialog.ngOnDestroy();
      }
    })
  }

  clear() {
    this.dialog.ngOnDestroy();
  }
}
