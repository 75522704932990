import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CenterService } from 'src/app/shared/services/center.service';

export class CALSchoolBatch {
  public std: string = '';
  public batchname: string = ''
}

@Component({
  selector: 'app-createstandardwisebatchpopup',
  templateUrl: './createstandardwisebatchpopup.component.html',
  styleUrls: ['./createstandardwisebatchpopup.component.scss']
})
export class CreatestandardwisebatchpopupComponent implements OnInit {
  UserId;
  pgname;
  Userrole;
  pgid;
  createbatchform: FormGroup;
  schoolid;
  loading = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,private router: Router, private toastr: ToastrService,
    private fb: FormBuilder, public service: CenterService) {
    this.UserId = localStorage.getItem("UserId");
    this.pgname = localStorage.getItem("pgname");
    this.Userrole = localStorage.getItem("role");
    this.pgid = localStorage.getItem("pgid");
    this.schoolid = data.id;

  }

  ngOnInit() {
    this.createbatchform = this.fb.group({
      standard: ['', Validators.required],
      batch: ['', Validators.required],
    });
  }


  AddBatch() {
    this.loading = true;
    const createbatch: CALSchoolBatch = new CALSchoolBatch();
    createbatch.std = this.createbatchform.value.standard;
    createbatch.batchname = this.createbatchform.value.batch;
    console.log(this.schoolid, createbatch);

      this.service.AddCALSchoolBatch(this.schoolid,createbatch).subscribe((res) => {
        if (res == 'Success') {
          this.toastr.success ("Batch Created SuccessFully");
          this.dialog.ngOnDestroy();
          this.router.navigateByUrl('/CenterMenu', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/SchoolDetails',this.schoolid]));
         this.loading = false;
        } else if(res == 'Exits'){
          this.toastr.error('Batch Already Exist');
          this.dialog.ngOnDestroy();
          this.router.navigateByUrl('/CenterMenu', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/SchoolDetails',this.schoolid]));
          this.loading = false;
        }else if(res == 'Failure'){  
          this.toastr.error('Failed to Create Batch');
          this.dialog.ngOnDestroy();
          this.router.navigateByUrl('/CenterMenu', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/SchoolDetails',this.schoolid]));
          this.loading = false;
        }
       error => {
         console.log(error);
       }

     });

  }

  clear() {
    this.dialog.ngOnDestroy();
  }

}
