import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BnNgIdleService } from 'bn-ng-idle';
import { DialogpopupComponent } from './layout/employeemodule/dialogpopup/dialogpopup.component';
import { ForgotpasswordpopupComponent } from './layout/employeemodule/forgotpasswordpopup/forgotpasswordpopup.component';
import { MatInputModule, MatFormFieldModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OtppopupComponent } from './layout/employeemodule/otppopup/otppopup.component';
import { LoanpopupComponent } from './layout/employeemodule/loanpopup/loanpopup.component';
import { SalarybrekuppopupComponent } from './layout/employeemodule/salarybrekuppopup/salarybrekuppopup.component';
import { SalaryincrementpopupComponent } from './layout/employeemodule/salaryincrementpopup/salaryincrementpopup.component';
import { SortheaderPipe } from './shared/services/sortheader.pipe';
import { EditprogrampopupComponent } from './layout/employeemodule/editprogrampopup/editprogrampopup.component';
import { EditstatuspopupComponent } from './layout/center/IDI/IDIstudentmodule/editstatuspopup/editstatuspopup.component';
import { CreatebatchpopupComponent } from './layout/Schoolmodule/createbatchpopup/createbatchpopup.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FeedbackredirectionpopupComponent } from './layout/DigitalEmployeeSancharak/dsfeedbacklist/feedbackredirectionpopup/feedbackredirectionpopup.component';
import { DailyworksummaryComponent } from './layout/dailytaskreport/dailyworksummary/dailyworksummary.component';
import { HardwareotherdetailpopupComponent } from './layout/center/Hardware/hardwareotherdetailpopup/hardwareotherdetailpopup.component';
import { UpdateemployeepersonaldetpopupComponent } from './layout/MyJankari/updateemployeepersonaldetpopup/updateemployeepersonaldetpopup.component';
import { UpdatecontactinfopopupComponent } from './layout/MyJankari/updatecontactinfopopup/updatecontactinfopopup.component';
import { AddnewqualificationpopupComponent } from './layout/MyJankari/addnewqualificationpopup/addnewqualificationpopup.component';
import { CreatestandardwisebatchpopupComponent } from './layout/Schoolmodule/createstandardwisebatchpopup/createstandardwisebatchpopup.component';
import { EmpprogramassigncenterpopupComponent } from './layout/employeemodule/empprogramassigncenterpopup/empprogramassigncenterpopup.component';
import { IdiactivitygalleryviewComponent } from './layout/center/IDI/idiactivitygalleryview/idiactivitygalleryview.component';
import { ConsultantappointmentletterComponent } from './layout/MyJankari/consultantappointmentletter/consultantappointmentletter.component';
import { EmployeeappointmentletterComponent } from './layout/MyJankari/employeeappointmentletter/employeeappointmentletter.component';
import { PermissionpopupComponent } from './layout/employeemodule/permissionpopup/permissionpopup.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BatchwiseattdetailspopupComponent } from './layout/center/CAl-PIF/Reports/batchwiseattdetailspopup/batchwiseattdetailspopup.component';
import { DiwaliwishespopupComponent } from './diwaliwishespopup/diwaliwishespopup.component';
import { InactivetoactiveemployeeComponent } from './layout/employeemodule/employeestatuswiseinforeport/inactivetoactiveemployee/inactivetoactiveemployee.component';
import { EmployeedependancymessagesComponent } from './layout/employeemodule/employeedependancymessages/employeedependancymessages.component';
import { ConfirmpopupComponent } from './layout/employeemodule/employeestatuswiseinforeport/confirmpopup/confirmpopup.component';
import { AttendancewarningpopupComponent } from './layout/center/CAl-PIF/TimeTable/SchoolwisePlanTimeTable/attendancewarningpopup/attendancewarningpopup.component';
import { AddprobationpopupComponent } from './layout/employeemodule/probationemplist/addprobationpopup/addprobationpopup.component';
import { LeavecancelpopupComponent } from './layout/employeemodule/approveleavelist/leavecancelpopup/leavecancelpopup.component';
import { UpdateactionpointerpopupComponent } from './layout/center/CAl-PIF/Center Visit/visitfeedbackpage/centervisitfeedback/updateactionpointerpopup/updateactionpointerpopup.component';
import { UpdateattendancepopupComponent } from './layout/employeemodule/employeeattenadncemarkedin/updateattendancepopup/updateattendancepopup.component';
import { AttendancewarningpopupidiComponent } from './layout/center/IDI/Timetable/centerwiseplanTT/attendancewarningpopupidi/attendancewarningpopupidi.component';
import { UpdatedigitalsakshardialogComponent } from './layout/center/My E-school/Visit Form/visitformupdate/schoolvisitupdate/updatedigitalsakshardialog/updatedigitalsakshardialog.component';
import { VolunteerappointmentletterComponent } from './layout/MyJankari/volunteerappointmentletter/volunteerappointmentletter.component';
import { InternappointmentletterComponent } from './layout/MyJankari/internappointmentletter/internappointmentletter.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        ToastrModule.forRoot(),
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule, ReactiveFormsModule, FormsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    entryComponents: [DialogpopupComponent, ForgotpasswordpopupComponent, OtppopupComponent, LoanpopupComponent, SalarybrekuppopupComponent, SalaryincrementpopupComponent, EditprogrampopupComponent, EditstatuspopupComponent, CreatebatchpopupComponent, FeedbackredirectionpopupComponent, DailyworksummaryComponent, HardwareotherdetailpopupComponent, UpdateemployeepersonaldetpopupComponent, UpdatecontactinfopopupComponent, AddnewqualificationpopupComponent, CreatestandardwisebatchpopupComponent, EmpprogramassigncenterpopupComponent, IdiactivitygalleryviewComponent, ConsultantappointmentletterComponent, EmployeeappointmentletterComponent, PermissionpopupComponent, BatchwiseattdetailspopupComponent, DiwaliwishespopupComponent, InactivetoactiveemployeeComponent, EmployeedependancymessagesComponent, ConfirmpopupComponent, AttendancewarningpopupComponent, AddprobationpopupComponent, LeavecancelpopupComponent, UpdateactionpointerpopupComponent, UpdateattendancepopupComponent, AttendancewarningpopupidiComponent, UpdatedigitalsakshardialogComponent, VolunteerappointmentletterComponent, InternappointmentletterComponent],
    declarations: [AppComponent, DialogpopupComponent, ForgotpasswordpopupComponent, OtppopupComponent, LoanpopupComponent, SalarybrekuppopupComponent, SalaryincrementpopupComponent, SortheaderPipe, EditprogrampopupComponent, EditstatuspopupComponent, CreatebatchpopupComponent, FeedbackredirectionpopupComponent, DailyworksummaryComponent, HardwareotherdetailpopupComponent, UpdateemployeepersonaldetpopupComponent, UpdatecontactinfopopupComponent, AddnewqualificationpopupComponent, CreatestandardwisebatchpopupComponent, EmpprogramassigncenterpopupComponent, IdiactivitygalleryviewComponent, ConsultantappointmentletterComponent, EmployeeappointmentletterComponent, PermissionpopupComponent, BatchwiseattdetailspopupComponent, DiwaliwishespopupComponent, InactivetoactiveemployeeComponent, EmployeedependancymessagesComponent, ConfirmpopupComponent, AttendancewarningpopupComponent, AddprobationpopupComponent, LeavecancelpopupComponent, UpdateactionpointerpopupComponent, UpdateattendancepopupComponent, AttendancewarningpopupidiComponent, UpdatedigitalsakshardialogComponent, VolunteerappointmentletterComponent, InternappointmentletterComponent],
    providers: [AuthGuard, BnNgIdleService, DeviceDetectorService],
    bootstrap: [AppComponent],

})
export class AppModule { }
