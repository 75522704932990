import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(private http: HttpClient) {}
  SchoolList(userid: string, role: string, pgid: string, where): any {
      return this.http.get(environment.apiUrl + 'Center/' + 'SchoolList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  CenterwiseSchoolDetail(centid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'CenterwiseSchoolDetail/' + centid );
  }

  AddSchool(pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddSchool/' + pgid, data );
  }

  UpdateSchool(schoolid: string, pgid: string , AddSchool: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchool/' + schoolid + '/' + pgid , AddSchool);
  }

  GetSchool(schoolid: string , pgid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchool/' + schoolid + '/' + pgid );
  }

  AddSchoolGst(schoolid: string , data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddSchoolGst/' + schoolid , data);
  }

  AddSchoolEnrollment(schoolid: string , data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddSchoolEnrollment/' + schoolid , data);
  }

  GetSchoolEnrollment(schoolid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolEnrollment/' + schoolid );
  }

  RemoveSchoolEnrollment(enrollid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'RemoveSchoolEnrollment/' + enrollid);
  }

  SchoolGstList(schoolid: string): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'SchoolGstList/' + schoolid );
  }

  // UploadSchoolGst(schoolid: string, applicable: string, academic: string, data: any) {
  //   return this.http.post(environment.apiUrl + 'Center/' + 'UploadSchoolGst/' + schoolid + '/' + applicable + '/' + academic , data);
  // }

  UploadSchoolGst(schoolid: string, applicable: string, academic: string,  fileToUpload: File   ) {
    console.log(fileToUpload);
     const formData: FormData = new FormData();
        formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Center/' + 'UploadSchoolGst/' + schoolid + '/' + applicable + '/' + academic , formData   );
  }

  GetSchoolSurveyList(userid: string, role: string, pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolSurveyList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
}
AddSchoolSurvey(UserId: string, data: any) {
  return this.http.post(environment.apiUrl + 'Center/' + 'AddSchoolSurvey/' + UserId, data );
}

GetSchoolSurvey(surverid: string): any {
  return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolSurvey/' + surverid);
}

UpdateSchoolSurvey(surverid: string , data: any) {
  return this.http.post(environment.apiUrl + 'Center/' + 'UpdateSchoolSurvey/' + surverid , data);
}
RptSchool(userid: string , role: string , pgid: string, whr): any {
  return this.http.get(environment.apiUrl + 'Center/' + 'RptSchool/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
}


GetSchoolStandard(schoolid: string): any {
  return this.http.get(environment.apiUrl + 'Center/' + 'GetSchoolStandard/' + schoolid);
}
AddCALBatch(schoolid: string, data: any) {
  return this.http.post(environment.apiUrl + 'Center/' + 'AddCALBatch/' + schoolid, data);
}
GetCALBatch(schoolid: string): any {
  return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBatch/' + schoolid);
}

GetRptATALSchoolWeekAttendance(userid: string, role: string , pgid: string , where): any {
  return this.http.get(environment.apiUrl + 'Student/' + 'GetRptATALSchoolWeekAttendance/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  
   GetCALBatchDivisionDetail(schoolid: string): any {
  return this.http.get(environment.apiUrl + 'Center/' + 'GetCALBatchDivisionDetail/' + schoolid);
}

}
