import { Component, OnInit, Input, Inject } from '@angular/core';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialogpopup',
  templateUrl: './dialogpopup.component.html',
  styleUrls: ['./dialogpopup.component.scss'],
  
})
export class DialogpopupComponent implements OnInit {
  empid=null;
  grosssalary;
  Salarybrekup;
  empid1;
  employeeId;
  count;
 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private service: MasterServiceService,public dialog: MatDialog,private router: Router,private route: ActivatedRoute) {
  
    console.log("data"+data.id);

     this.empid = localStorage.getItem("empid"); 
  
  
        service.GetEmployeeSalaryBreakDown(data.id).subscribe((data: any) => {
          
      this.Salarybrekup = data;
    });
   
    service.GetEmployeeSalaryBreakDown(data.id).subscribe((data: any) => {

      if (data == "") {

        this.count = 0;
      }
      else if (data == null && data[0] == null) {

        this.count = 0;
      }
      else {
        this.count = data[0].length;
      }

     
    })
   }


   clear(){
   //localStorage.removeItem('empid');
    this.dialog.ngOnDestroy();
   }
  ngOnInit() {
  }

}
