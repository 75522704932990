import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CenterService } from 'src/app/shared/services/center.service';

@Component({
  selector: 'app-hardwareotherdetailpopup',
  templateUrl: './hardwareotherdetailpopup.component.html',
  styleUrls: ['./hardwareotherdetailpopup.component.scss']
})
export class HardwareotherdetailpopupComponent implements OnInit {
  centid;
  centerhardwarecalldetails;
  count;
  tlname;
  tlmobno;
  pcname;
  pcno;
  paname;
  pano;
  ohname;
  ohno;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any , public dialog: MatDialog , public centservice: CenterService) {
    this.centid = data.id ;

    this.centservice.GetCenterHardwareCallDetail(this.centid).subscribe((data : any) => {
      this.centerhardwarecalldetails = data;
      this.tlname = data.TLName;
      this.tlmobno = data.TLmobileno;
      this.pcname = data.PCName;
      this.pcno = data.PCmobileno;
      this.paname = data.ProgassociateName;
      this.pano = data.ProgAssmobileno;
      this.ohname = data.OHName;
      this.ohno = data.OHmobileno;
    });

    
   }

  ngOnInit() {
  }

  clear() {
     this.dialog.ngOnDestroy();
    }
}
