import { Component, OnInit ,EventEmitter} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Forgetpassword } from 'src/app/ClassFiles/forgetpassword';

@Component({
  selector: 'app-otppopup',
  templateUrl: './otppopup.component.html',
  styleUrls: ['./otppopup.component.scss']
})
export class OtppopupComponent implements OnInit {
otp:string;
otpuserId;

submited = new EventEmitter<string>();

  constructor(private fb: FormBuilder,public dialog: MatDialog, private router: Router, private service: MasterServiceService,private toastr: ToastrService) { 

    this.otpuserId = localStorage.getItem("otpdata");
  }

  ngOnInit() {
  }
  // Submit(){
  
  //   this.toastr.success("Sucess")
  //   this.dialog.closeAll()
  //   this.router.navigate(['/change-password'])

  // }


  
  Submit(){
  const data : Forgetpassword = new Forgetpassword();

  data.username = this.otpuserId;
  data.dummypass = this.otp

    this.service.CheckOTP(data).subscribe((res) => {

     this.submited.emit(res.toString());
     
     if(res.toString()=="Success"){
      localStorage.setItem('otp',this.otp);
       this.toastr.success("OTP Verify Successfully")
       this.dialog.closeAll()
    this.router.navigate(['/change-password'])
          
     }
     else{
       
   this.toastr.error ("Enter Proper OTP");
     }
      
          },
     error => {
       console.log(error);
     }
   );
     
     }
}
