export class Emppramotion {

    constructor(
        public  empid: string = "",
        public finyear: string = "",
        public oldctc: string = "",
        public oldgross: string = "",
        public incregross: string = "",
        public newgross: string = "",
        public newctc: string = "",
        
    ){}
}
