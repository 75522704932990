import { Component, OnInit,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { OtppopupComponent } from '../otppopup/otppopup.component';
import { Forgetpassword } from 'src/app/ClassFiles/forgetpassword';

@Component({
  selector: 'app-forgotpasswordpopup',
  templateUrl: './forgotpasswordpopup.component.html',
  styleUrls: ['./forgotpasswordpopup.component.scss']
})
export class ForgotpasswordpopupComponent implements OnInit {
otpform:FormGroup;
otpdata:string;
submited = new EventEmitter<string>();

  constructor(private fb: FormBuilder,public dialog: MatDialog, private service: MasterServiceService,private toastr: ToastrService) { }

  ngOnInit() {
    this.otpform = this.fb.group({
      mobile:['', Validators.required],
    })
  }


  Submit(){
  
 const data : Forgetpassword = new Forgetpassword();

 data.username = this.otpdata

 this.service.ForgotPassword(data).subscribe((res) => {
  this.submited.emit(res.toString());
  if(res.toString()=="Success"){
    localStorage.setItem('otpdata', this.otpdata);
    this.toastr.success("OTP send to your Mobile or Email")
    this.dialog.closeAll()
    this.dialog.open(OtppopupComponent, { disableClose: true });
       
  }
  else{
    
this.toastr.error ("Enter Proper Information");
  }
   
       },
  error => {
    console.log(error);
  }
);
  
  }

  openDialog() {
   
   }
}
