import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';

export class EmployeeJobDetail {
  public doj: Date = new Date();
}

@Component({
  selector: 'app-confirmpopup',
  templateUrl: './confirmpopup.component.html',
  styleUrls: ['./confirmpopup.component.scss']
})
export class ConfirmpopupComponent implements OnInit {
  doj;
  empid;
  loading;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public service: MasterServiceService, public dialog: MatDialog, private fb: FormBuilder, private toastr: ToastrService) {
    this.empid = data.empid
    this.doj = data.doj;
    
  }

  ngOnInit() {
  }

  activeemployee() {
    this.loading = true;
    const data: EmployeeJobDetail = new EmployeeJobDetail();
    data.doj = this.doj;

    this.service.ActiveEmployees(this.empid, data).subscribe((res: string) => {
      if (res == 'success') {
        this.loading = false;
        this.dialog.closeAll();
        this.toastr.success('Employee Active Sucessfully!')
      }
      else {
        this.loading = false;
        this.dialog.closeAll();
        this.toastr.error('Failed To Active!')
      }
    })
  }

  denied() {
    this.dialog.closeAll();
  }
}
