import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export class appintment {
  public employeename: string = "";
  public fcyear: string = "";
}

@Component({
  selector: 'app-internappointmentletter',
  templateUrl: './internappointmentletter.component.html',
  styleUrls: ['./internappointmentletter.component.scss']
})

export class InternappointmentletterComponent implements OnInit {
  appointmentdata;
  UserId;
  appointmentform: FormGroup;
  finyear;
  submitted = false;
  employeementtype;
  mousign;
  salaryData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private router: Router, private service: MasterServiceService, private toastr: ToastrService, private fb: FormBuilder,) {

    this.employeementtype = data.employeetype;
    this.UserId = localStorage.getItem("UserId");

    service.GetAppoinmentLetterDetail(this.UserId).subscribe((data: any) => {
      this.appointmentdata = data;
      this.mousign = data.mousign;
    })

    service.GetEmployeeSalaryBreakDown(this.UserId).subscribe((data: any) => {
      this.salaryData = data;
    });

    this.service.GetName('FinanacialYear', 'yearname', 'status=0').subscribe((data: any) => {
      this.finyear = data;
    });
  }

  ngOnInit() {
    this.appointmentform = this.fb.group({
      acceptTerms: [false, Validators.requiredTrue],
      name: ['', Validators.required]
    });
  }

  clear() {
    this.dialog.ngOnDestroy();
  }

  get f() { return this.appointmentform.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.appointmentform.invalid) {
      return;
    }
    const data: appintment = new appintment();
    data.employeename = this.appointmentform.value.name;
    data.fcyear = this.finyear

    this.service.EmployeeAppointmentDownload(this.UserId, data).subscribe((res: any) => {

      if (res == 'Success') {

        let url = this.router.createUrlTree(['/InternAppointmentLetter', this.UserId])
        window.open(url.toString(), '_blank')

        this.dialog.ngOnDestroy();
        this.router.navigate(["/Home"]);
        this.appointmentform.reset();
      }
      else if (res == 'Exists') {
        this.toastr.error("Appointment Letter Already Downloaded");
      }
      else {
        this.toastr.error("Please Enter proper Your First Name");

      }
    })
  }
}
