import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmpopupComponent } from '../confirmpopup/confirmpopup.component';

export class EmployeeJobDetail {
  public doj: Date = new Date();
}

@Component({
  selector: 'app-inactivetoactiveemployee',
  templateUrl: './inactivetoactiveemployee.component.html',
  styleUrls: ['./inactivetoactiveemployee.component.scss']
})
export class InactivetoactiveemployeeComponent implements OnInit {
  empname;
  empid;
  userid;
  status;
  yr20;
  ActiveForm: FormGroup;
  doj;
  loading;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: MasterServiceService, public dialog: MatDialog, private fb: FormBuilder, private toastr: ToastrService) {
    this.empname = data.fullname;
    this.empid = data.empid;
  }

  ngOnInit() {
    this.ActiveForm = this.fb.group({
      stat: ['', Validators.required],
      doj: ['', Validators.required],
    })
  }

  ChangeStatus() {
    this.loading = true;
    const data : EmployeeJobDetail = new EmployeeJobDetail ();
    data.doj = this.ActiveForm.value.doj;
    console.log(data.doj);
    

    this.service.ActiveEmployee(this.empid, data).subscribe((res: string) => {
      if (res == 'Entry already exists 2 or more times!') {
        this.loading = false;
        this.openDialog();
      }
      else {
        this.loading = false;
        this.dialog.ngOnDestroy();
        this.toastr.success('Employee Active Sucessfully!')
      }
    })
  }

  clear() {
    this.dialog.ngOnDestroy();
  }

  openDialog() {
    this.dialog.open(ConfirmpopupComponent, {
      data: {
        empid: this.empid,
        doj: this.ActiveForm.value.doj
      }
    });
  }
}
