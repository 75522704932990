import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-diwaliwishespopup',
  templateUrl: './diwaliwishespopup.component.html',
  styleUrls: ['./diwaliwishespopup.component.scss']
})
export class DiwaliwishespopupComponent implements OnInit {

  constructor( public dialog: MatDialog) { }

  ngOnInit() {
  }
  clear() {
    this.dialog.ngOnDestroy();
  }
}
