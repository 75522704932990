import { Component, OnInit ,Inject} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EmployeeProgram } from 'src/app/ClassFiles/employee-program';

@Component({
  selector: 'app-editprogrampopup',
  templateUrl: './editprogrampopup.component.html',
  styleUrls: ['./editprogrampopup.component.scss']
})
export class EditprogrampopupComponent implements OnInit {
  EmpProgramForm: FormGroup;
  reportsto ;
  Designationlist;
  empid;
  id;
  depid;
  EmployeeProgram;
  desid;
  partnerlist;
  project;
  UserId
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog,private toastr: ToastrService,private service: MasterServiceService,private fb: FormBuilder) { 
    this.empid = data.empid;
    this.id = data.id;
    this.depid = data.depid;
    this.desid = data.desid
    this.UserId = localStorage.getItem("UserId");

    this.service.FillDropDown("Designation", "distinct Designation.desname", "Designation.desid", " where depid='" + this.depid + "'").subscribe((data: any) => {
      this.Designationlist = data; });
      service.GetUpdateEmployeeProgram(this.id,this.empid).subscribe((data: any) => {
        this.EmployeeProgram = data;
      })
      this.service.GetReportstoEmp(this.desid).subscribe((data: any) => {  this.reportsto = data; 
    
      });
        this.service.FillDropDown("partner", "distinct partner.partnername", "partner.partnerid", " where status=0 order by partner.partnername").subscribe((data: any) => {
          this.partnerlist = data;
        })
        service.FillDropDown("projectmaster", "projectname", "projectname", "").subscribe((data: any) => {
          this.project = data;
       
        }); 
  }

  ngOnInit() {
    this.EmpProgramForm = this.fb.group({
      desid: ['', Validators.required],
      reportto: ['', Validators.required],
      partner: ['', Validators.required],
      project: ['', Validators.required],
    });
  }
  clear(){
   
     this.dialog.ngOnDestroy();
    }
    onSelectDesignation(desid) {
      if (desid == "")
        this.reportsto = null;
      else
        this.service.GetReportstoEmp(desid).subscribe((data: any) => {  this.reportsto = data; });
}

UpdateEmpProgram(){
  const Empprogram: EmployeeProgram = new EmployeeProgram();
  Empprogram.empid=this.empid 
  Empprogram.desid = this.EmpProgramForm.value.desid;
  Empprogram.reportto = this.EmpProgramForm.value.reportto;
  Empprogram.partnerid = this.EmpProgramForm.value.partner;
  Empprogram.project = this.EmpProgramForm.value.project;
  Empprogram.updateby = this.UserId;

  this.service.UpdateEmployeeProgram(this.id,this.empid,Empprogram).subscribe((res) => {
    console.log(res);
    this.toastr.success("Update SuccessFully");
    this.dialog.ngOnDestroy();
  },
    error => {
      console.log(error);
    }
  );
}
}
