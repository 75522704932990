import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export class Employee {
  public probationenddate: Date = new Date();
  public probationperiod: string = '';
  public empid: string = '';
}

@Component({
  selector: 'app-addprobationpopup',
  templateUrl: './addprobationpopup.component.html',
  styleUrls: ['./addprobationpopup.component.scss']
})
export class AddprobationpopupComponent implements OnInit {
  empname;
  empid;
  doj;
  status;
  ActiveForm: FormGroup;
  loading;
  Updatedby;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: MasterServiceService, public dialog: MatDialog, private fb: FormBuilder, private toastr: ToastrService, private router: Router) {
    this.Updatedby = localStorage.getItem("UserId")
    this.empname = data.fullname;
    this.empid = data.empid;
    this.doj = data.doj;
  }

  ngOnInit() {
    this.ActiveForm = this.fb.group({
      period: ['', Validators.required],
      enddate: ['', Validators.required],
    })
  }

  ChangeStatus() {
    this.loading = true;
    const data: Employee = new Employee();
    data.empid = this.empid;
    data.probationenddate = this.ActiveForm.value.enddate;
    data.probationperiod = this.ActiveForm.value.period;


    this.service.AddEmployeeProbation(this.Updatedby, data).subscribe((res: string) => {
      if (res == 'Success') {
        this.loading = false;
        this.dialog.ngOnDestroy();
        this.toastr.success('Changes Updated Sucessfully!')
        this.router.navigateByUrl('/CenterMenu', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/ProbationEmployeeList']));
        // this.router.navigate(['/ProbationEmployeeList']);
      }
      else {
        this.loading = false;
        this.dialog.ngOnDestroy();
      }
    })
  }

  clear() {
    this.dialog.ngOnDestroy();
  }
}
