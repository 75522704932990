import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CenterService } from 'src/app/shared/services/center.service';

@Component({
  selector: 'app-idiactivitygalleryview',
  templateUrl: './idiactivitygalleryview.component.html',
  styleUrls: ['./idiactivitygalleryview.component.scss']
})
export class IdiactivitygalleryviewComponent implements OnInit {
acid;
UserId;
pgid;
role;
pgname;
imgvideo;
doctype;
  constructor( private service: CenterService,private route: ActivatedRoute,@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog) {
    this.acid = data.id;
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.pgname = localStorage.getItem('pgname');
    this.role = localStorage.getItem('role');

    this.service.GetIDIActivityNotificationDetail(this.UserId, this.role, this.pgid,"where IDIActivityMaster.acid='"+this.acid+"' and IDIActivityDetail.status=0").subscribe(
      (data: any) => {
      this.imgvideo = data;
      for (let i = 0; i < data.length; i++) {
        this.doctype = data[i].doctype;
        console.log(this.doctype)
      }
      });
  }

  ngOnInit() {
  }

  clear() {
    console.log('yes')
    this.dialog.ngOnDestroy();
   }
}
