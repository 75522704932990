export class EmployeeProgram {

    constructor(


        public empid: string = '',
        public batchid: string = '',
        public finyear: string = '',
        public courseid: string = '',
        public pgid: string = '',
        public depid: string = '',
        public desid: string = '',
        public reportto: string = '',
        public partnerid: string = '',
        public pactive: string = '',
        public updateby: string = '',
        public startdate: Date = new Date(),
        public enddate: Date = new Date(),
        public status: string = '',
        public project: string = '',

        ) { }
}
