import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StudentService } from 'src/app/shared/services/student.service';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';

export class DigitalSaksharForm {

  public digitalclassroomno : string = '';
  public teacherusingdsclassroom : string = '';
  public teacherusingdsclassroomtypereason : string = '';
  public classaccordingdstt : string = '';
  public whichclassttrunning : string = '';
  public whichsubjectteaching : string = '';
  public whichtopicteaching : string = '';
  public classaccordingdsttypereason : string = '';
  public useofvisitds : string = '';
  public useofvisitdsreason : string = '';
  public dsmethod : string = '';
  public teachername : string = '';
  public dsusinghelp : string = '';
  public dsusinghelpreason : string = '';
  public videorecordinginnotebook : string = '';
  public hardwareissue : string = '';
  public softwareissue : string = '';
  public hardwareissueotherreason : string = '';
  public softwareissueotherreason : string = '';
  public videorecordinginnotebookreason: string = '';
  public younginstructoractive: string = '';
  public younginstructoractivereason: string = '';
  public availabledigitaldevices  : string = '';
  public availabledigitaldevicesoption : string = '';
  public studentcount: string = '';
  public allnoreason: string = '';
  public allnoremark: string = '';
  public studentpresentcount: string = '';

}

@Component({
  selector: 'app-updatedigitalsakshardialog',
  templateUrl: './updatedigitalsakshardialog.component.html',
  styleUrls: ['./updatedigitalsakshardialog.component.scss']
})

export class UpdatedigitalsakshardialogComponent implements OnInit {

  loading =false;
  userid;
  digitalSakshar:FormGroup;
  q17;
  q18;
  // q19;
  q20;
  q21;
  q22;
  q23;
  // q24;
  q25;
  // q26;
  q27;
  q28;
  q30;
  q31;
  q32;
  q35;
  q36;
  hardwareOther;
  formData;
  softwareOther;
  instructor;
  instructor_reason;
  q32_reason;
  availabeDevice;
  isWorkingDevice;
  isWorkingDeviceReason;
  studentCount;
  reason;
  remark;
  schoolid;
  standard;
  Userrole;
  pgid;
  studPresentCount;
  
  language;
  q_yes;
  q_no;
  digital_literacy;
  q18_digitalClassNo;
  q19_availableDigitalEquip;
  q19_tv
  q19_projector
  q19_tab;
  q20_digitalDeviceOperational;
  q21_usuingDigiClass;
  q22_accordDigiTimeTable;
  q23_classConducted;
  q24_noOfStudents;
  q25_whichSubjectTeach;
  q25_Marathi;
  q25_English;
  q25_Math;
  q25_GeneralScience;
  q25_History;
  q25_Sanskrut;
  q25_Geography;
  q25_Geometry;
  q25_ComputerScience;
  q25_FoundationCourse;
  q25_Civics;
  q25_EVS1;
  q25_EVS2;
  q26_whichTopicTeach;
  q27_StudyMethodUsed;
  q27_HybridLearning;
  q27_SelfStudy;
  q28_NameOfTeacher;
  q29_FurtherAssistance;
  q30_WhoseAssistanceRequired;
  q31_VideoInstruction;
  q32_YoungInstructor;
  q33_HardwareIssue;
  q33_HardwareOtherIssue;
  q33_SoftwareIssue;
  q33_SoftwareOtherIssue;
  q34_NoOfStudentPresent;
  q36_AfterVisitUsingDigiApp;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any ,public dialog: MatDialog ,private fb: FormBuilder, private toastr: ToastrService,private service:StudentService,private router:Router,private jankariservice: MasterServiceService) {
    this.userid = localStorage.getItem("UserId");
    this.Userrole = localStorage.getItem("role");
    this.pgid = localStorage.getItem("pgid");
    this.formData = data;

    this.jankariservice.FillDropDown("STUDENT", "distinct standard", "standard", "where schoolid = '" + this.formData.schoolid + "'").subscribe((data:any) => {
        this.standard = data;
      });
  }

  disableNumberInputControl(event: Event) {
    
    const target = event.target as HTMLInputElement;
    
    target.addEventListener('wheel', (e) => {
      e.preventDefault();
    });
    target.addEventListener('keydown', (e) => {
      const keysToDisable = ['ArrowUp', 'ArrowDown'];

      if (keysToDisable.includes(e.key)) {
        e.preventDefault();
      }
    });
  }
  
  ngOnInit() {
    
    this.q17 = this.formData.digitalclassroomno;
    this.q18 = this.formData.teacherusingdsclassroom;
    // this.q19 = this.formData.teacherusingdsclassroomtypereason;
    this.q20 = this.formData.classaccordingdstt;
    this.q21 = this.formData.whichclassttrunning;
    this.q22 = this.formData.whichsubjectteaching;
    this.q23 = this.formData.whichtopicteaching;
    // this.q24 = this.formData.classaccordingdsttypereason;
    this.q25 = this.formData.useofvisitds;
    // this.q26 = this.formData.useofvisitdsreason;
    this.q27 = this.formData.dsmethod;
    this.q28 = this.formData.teachername;
    this.q30 = this.formData.dsusinghelp;
    this.q31 = this.formData.dsusinghelpreason;
    this.q32 = this.formData.videorecordinginnotebook;
    this.q35 = this.formData.hardwareissue;
    this.q36 = this.formData.softwareissue;
    this.instructor = this.formData.younginstructoractive;
    this.instructor_reason = this.formData.younginstructoractivereason;
    this.q32_reason = this.formData.videorecordinginnotebookreason;
    this.availabeDevice = this.formData.availabledigitaldevices;  
    this.isWorkingDevice = this.formData.availabledigitaldevicesoption; 
    // this.isWorkingDeviceReason = this.formData.availabledigitaldevicesreason;
    this.hardwareOther = this.formData.hardwareissueotherreason;
    this.softwareOther = this.formData.softwareissueotherreason;
    this.studentCount = this.formData.studentcount;
    this.studPresentCount = this.formData.studentpresentcount;
    this.reason = this.formData.allnoreason;
    this.remark = this.formData.allnoremark;
    this.schoolid = this.formData.schoolid;
    this.language = this.formData.language;
    
    this.digitalSakshar = this.fb.group({
      digitalclassroomno: [''],
      teacherusingdsclassroom: [''],
      // teacherusingdsclassroomtypereason: [''],
      classaccordingdstt: [''],
      whichclassttrunning: [''],
      whichsubjectteaching: [''],
      // classaccordingdsttypereason: [''],
      useofvisitds: [''],
      // useofvisitdsreason: [''],
      whichtopicteaching: [''],
      dsmethod: [''],
      qa28: [''],
      qa30: [''],
      qa31: [''],
      qa32: [''],
      qa35: [''],
      qa36: [''],
      instructor1:[''],
      instructor1_reason:[''],
      softwareOther1:[''],
      hardwareOther1:[''],
      qa32_reason:[''],
      availabeDevice1:[''],
      isWorkingDevice1:[''],
      // isWorkingDeviceReason1:[''],
      studentCount1: [''],
      reason: [''],
      remark: [''],
      studPresentCount1:['']
    });
    this.digitalSakshar.get('studentCount1').disable();

    if(this.language == 'Marathi'){

      this.q_yes = 'हो';
      this.q_no = 'नाही';
      this.digital_literacy = 'डिजिटल साक्षरचा अध्यापनासाठी वापर';
      this.q18_digitalClassNo = 'डिजिटल क्लासरूम क्रमाक';
      this.q19_availableDigitalEquip = 'उपलब्ध डिजिटल उपकरण';
      this.q19_tv = 'टीव्ही';
      this.q19_projector = 'प्रॉजेक्टर';
      this.q19_tab = 'टॅब';
      this.q20_digitalDeviceOperational = 'डिजिटल ऊपकरणे कार्यरत का ?';
      this.q21_usuingDigiClass = 'वर्ग शिक्षक डिजिटल क्लासरूमचा वापर करत होते का ?';
      this.q22_accordDigiTimeTable = 'डिजिटल तासिकांचे वेळापत्रका नुसार वर्ग घेत होते का ?';
      this.q23_classConducted = 'कोणत्या इयत्तेची तासिका चालू होती ?';
      this.q24_noOfStudents = 'विद्यार्थी संख्या';
      this.q25_whichSubjectTeach = 'कोणत्या विषयाचे अध्ययन/ अध्यापन चालु होते ?';
      this.q25_Marathi = 'मराठी';
      this.q25_English = 'इंग्लिश';
      this.q25_Math = 'गणित';
      this.q25_GeneralScience = 'सा.विज्ञान';
      this.q25_History = 'इतिहास';
      this.q25_Geography = 'भूगोल';
      this.q25_Sanskrut = 'संस्कृत';
      this.q25_Geometry = 'भूमिती';
      this.q25_ComputerScience = 'संगणक विज्ञान';
      this.q25_FoundationCourse = 'फाऊंडेशन कोर्स';
      this.q25_Civics = 'नागरिकशास्त्र';
      this.q25_EVS1 = 'परिसर अभ्यास १';
      this.q25_EVS2 = 'परिसर अभ्यास २';
      this.q26_whichTopicTeach = 'कोणत्या घटकाचे अध्ययन /अध्यापन चालू होते ?';
      this.q27_StudyMethodUsed = 'खालीलपैकी कोणत्या अध्ययन पद्धतीचा वापर होतो ?';
      this.q27_HybridLearning = 'हायब्रीड लर्निंग';
      this.q27_SelfStudy = 'स्वयं अध्ययन';
      this.q28_NameOfTeacher = 'संवाद केलेल्या शिक्षकाचे नाव';
      this.q29_FurtherAssistance = 'डिजिटल साक्षर हाताळण्यासाठी / वापरण्यासाठी अधिक मदतीची गरज आहे का ?';
      this.q30_WhoseAssistanceRequired = 'मदतीची गरज असल्यास नेमकी कशाची ?';
      this.q31_VideoInstruction = 'व्हिडिओ अध्यापनाद्वारे शिकवणार असलेल्या घटकाची टाचण वहीमध्ये नोंद करण्यात आलेली आहे का ?';
      this.q32_YoungInstructor = 'आपल्या वर्गातील young instructor सक्रिय आहे का ?';
      this.q33_HardwareIssue = 'वारंवार येणाऱ्या तांत्रिक अडचणी (Hardware)';
      this.q33_HardwareOtherIssue = 'वारंवार येणाऱ्या तांत्रिक अडचणी (Hardware - Other)';
      this.q33_SoftwareIssue = 'वारंवार येणाऱ्या तांत्रिक अडचणी (Software)';
      this.q33_SoftwareOtherIssue = 'वारंवार येणाऱ्या तांत्रिक अडचणी (Software - Other)';
      this.q34_NoOfStudentPresent = 'उपस्थित विद्यार्थी संख्या';
      this.q36_AfterVisitUsingDigiApp = 'तुम्ही भेट दयायला आलेले पाहून डिजिटल साक्षर वापरण्यास सुरुवात केली का ?';

    }
    else if(this.language == 'English'){

      this.q_yes = 'Yes';
      this.q_no = 'No';
      this.digital_literacy = 'Utilizing digital literacy for teaching';
      this.q18_digitalClassNo = 'Digital Classroom Number';
      this.q19_availableDigitalEquip = 'Available digital equipment';
      this.q19_tv = 'Television';
      this.q19_projector = 'Projector';
      this.q19_tab = 'Tab';
      this.q20_digitalDeviceOperational = 'Are the digital devices operational ?';
      this.q21_usuingDigiClass = 'Were classroom teachers utilizing digital classrooms ?';
      this.q22_accordDigiTimeTable = 'Did classes take place according to the digital timetable ?';
      this.q23_classConducted = "Which grade level's classes were conducted ?";
      this.q24_noOfStudents = 'Number of students';
      this.q25_whichSubjectTeach = 'Which subjects were being taught/studied ?';
      this.q25_Marathi = 'Marathi';
      this.q25_English = 'English';
      this.q25_Math = 'Math';
      this.q25_GeneralScience = 'General Science';
      this.q25_History = 'History';
      this.q25_Geography = 'Geography';
      this.q25_Geometry = 'Geometry';
      this.q25_Sanskrut = 'Sanskrut';
      this.q25_ComputerScience = 'Computer Science';
      this.q25_FoundationCourse = 'Foundation Course';
      this.q25_Civics = 'Civics';
      this.q25_EVS1 = 'Environmental Science 1';
      this.q25_EVS2 = 'Environmental Science 2';
      this.q26_whichTopicTeach = 'Which components were being studied/taught ?';
      this.q27_StudyMethodUsed = 'Which of the following study methods were being used ?';
      this.q27_HybridLearning = 'Hybrid Learning';
      this.q27_SelfStudy = 'Self-Study';
      this.q28_NameOfTeacher = 'Name of the teacher with whom you communicated';
      this.q29_FurtherAssistance = 'Do you require further assistance for implementing/utilizing Digital Literacy ?';
      this.q30_WhoseAssistanceRequired = 'If assistance is needed, whose assistance is required ?';
      this.q31_VideoInstruction = 'Is the component that will be taught via video instruction documented in the notebook ?';
      this.q32_YoungInstructor = 'Is the young instructor active in your class ?';
      this.q33_HardwareIssue = 'Frequent technical problems (Hardware)';
      this.q33_HardwareOtherIssue = 'Frequent technical problems (Hardware - Other)';
      this.q33_SoftwareIssue = 'Frequent technical problems (Software)';
      this.q33_SoftwareOtherIssue = 'Frequent technical problems (Software - Other)';
      this.q34_NoOfStudentPresent = 'No of present student';
      this.q36_AfterVisitUsingDigiApp = 'After visit did they start utilizing Digital Sakshar app ?';

    }
  }

  onSelectStandard() {
    
    this.jankariservice.FillDropDown("STUDENT", "distinct studentid", "studentid", "where schoolid = '" + this.schoolid + "' and standard='" + this.q21 + "'").subscribe((data:any) => {
      this.studentCount = data.length;
    });

  }

  clearDigitalDeviceReason() {
    this.isWorkingDeviceReason = null;

    // const isWorkingDeviceReason1 = this.digitalSakshar.get('isWorkingDeviceReason1');
    // isWorkingDeviceReason1.clearValidators();
    // isWorkingDeviceReason1.updateValueAndValidity();

    const reason = this.digitalSakshar.get('reason');
    reason.clearValidators();
    reason.updateValueAndValidity();
    reason.reset();
    
    const remark = this.digitalSakshar.get('remark');
    remark.clearValidators();
    remark.updateValueAndValidity();
    remark.reset();
    
  }

  clearAllControll() {
    this.isWorkingDevice = 'No';

    if (this.isWorkingDevice == 'No' && this.q18 == 'No' && this.q25 == 'No') {

      const qa27 = this.digitalSakshar.get('dsmethod');
      const qa28 = this.digitalSakshar.get('qa28');
      const qa30 = this.digitalSakshar.get('qa30');
      const qa31 = this.digitalSakshar.get('qa31');
      const qa32 = this.digitalSakshar.get('qa32');
      const qa35 = this.digitalSakshar.get('qa35');
      const qa36 = this.digitalSakshar.get('qa36');
      const instructor1 = this.digitalSakshar.get('instructor1');
      const qa32_reason = this.digitalSakshar.get('qa32_reason');
      const instructor1_reason = this.digitalSakshar.get('instructor1_reason');
      const hardwareOther1 = this.digitalSakshar.get('hardwareOther1');
      const softwareOther1 = this.digitalSakshar.get('softwareOther1');
      const studentCount1 = this.digitalSakshar.get('studentCount1');

      qa27.clearValidators();
      qa27.updateValueAndValidity();
      qa27.reset();

      qa28.clearValidators();
      qa28.updateValueAndValidity();
      qa28.reset();

      qa30.clearValidators();
      qa30.updateValueAndValidity();
      qa30.reset();

      qa31.clearValidators();
      qa31.updateValueAndValidity();
      qa31.reset();

      qa32.clearValidators();
      qa32.updateValueAndValidity();
      qa32.reset();

      qa35.clearValidators();
      qa35.updateValueAndValidity();
      qa35.reset();

      qa36.clearValidators();
      qa36.updateValueAndValidity();
      qa36.reset();

      instructor1.clearValidators();
      instructor1.updateValueAndValidity();
      instructor1.reset();

      qa32_reason.clearValidators();
      qa32_reason.updateValueAndValidity();
      qa32_reason.reset();

      instructor1_reason.clearValidators();
      instructor1_reason.updateValueAndValidity();
      instructor1_reason.reset();

      hardwareOther1.clearValidators();
      hardwareOther1.updateValueAndValidity();
      hardwareOther1.reset();

      softwareOther1.clearValidators();
      softwareOther1.updateValueAndValidity();
      softwareOther1.reset();

      studentCount1.clearValidators();
      studentCount1.updateValueAndValidity();
      studentCount1.reset();

    }

    this.q18 = null;
    this.q25 = null;
    this.q20 = null;
    this.q21 = null;
    this.q28 = null;
    this.studentCount = null;
    this.q22 = null;
    this.q23 = null;
    this.q27 = null;
    this.q30 = null;
    this.q31 = null;
    this.q32 = null;
    this.q32_reason = null;	 
    this.instructor = null;	
    this.instructor_reason = null;
    this.q35 = null;	
    this.hardwareOther = null;	
    this.q36 = null;	
    this.softwareOther = null;	
    this.studPresentCount = null;

    const teacherusingdsclassroom = this.digitalSakshar.get('teacherusingdsclassroom');
    teacherusingdsclassroom.clearValidators();
    teacherusingdsclassroom.updateValueAndValidity();

    const useofvisitds = this.digitalSakshar.get('useofvisitds');
    useofvisitds.clearValidators();
    useofvisitds.updateValueAndValidity();

    const classaccordingdstt = this.digitalSakshar.get('classaccordingdstt');
    classaccordingdstt.clearValidators();
    classaccordingdstt.updateValueAndValidity();

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const studentCount1 = this.digitalSakshar.get('studentCount1');
    studentCount1.clearValidators();
    studentCount1.updateValueAndValidity();

    const studPresentCount1 = this.digitalSakshar.get('studPresentCount1');
    studPresentCount1.clearValidators();
    studPresentCount1.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

    const qa28 = this.digitalSakshar.get('qa28');
    qa28.clearValidators();
    qa28.updateValueAndValidity();

    const dsmethod = this.digitalSakshar.get('dsmethod');
    dsmethod.clearValidators();
    dsmethod.updateValueAndValidity();

    const qa30 = this.digitalSakshar.get('qa30');
    qa30.clearValidators();
    qa30.updateValueAndValidity();

    const qa32 = this.digitalSakshar.get('qa32');
    qa32.clearValidators();
    qa32.updateValueAndValidity();

    const qa31 = this.digitalSakshar.get('qa31');
    qa31.clearValidators();
    qa31.updateValueAndValidity();

    const qa32_reason = this.digitalSakshar.get('qa32_reason');
    qa32_reason.clearValidators();
    qa32_reason.updateValueAndValidity();

    const instructor1 = this.digitalSakshar.get('instructor1');
    instructor1.clearValidators();
    instructor1.updateValueAndValidity();

    const qa35 = this.digitalSakshar.get('qa35');
    qa35.clearValidators();
    qa35.updateValueAndValidity();

    const hardwareOther1 = this.digitalSakshar.get('hardwareOther1');
    hardwareOther1.clearValidators();
    hardwareOther1.updateValueAndValidity();

    const qa36 = this.digitalSakshar.get('qa36');
    qa36.clearValidators();
    qa36.updateValueAndValidity();

    const softwareOther1 = this.digitalSakshar.get('softwareOther1');
    softwareOther1.clearValidators();
    softwareOther1.updateValueAndValidity();

    const instructor1_reason = this.digitalSakshar.get('instructor1_reason');
    instructor1_reason.clearValidators();
    instructor1_reason.updateValueAndValidity();

  }

  clearQ31(){
    this.q31 = null;
    const qa31 = this.digitalSakshar.get('qa31');
    qa31.clearValidators();
    qa31.updateValueAndValidity();
  }

  clearq32_reason(){
    this.q32_reason = null;
    const qa32_reason = this.digitalSakshar.get('qa32_reason');
    qa32_reason.clearValidators();
    qa32_reason.updateValueAndValidity();
  }

  clearinstructor_reason(){
    this.instructor_reason = null;
    const instructor1_reason = this.digitalSakshar.get('instructor1_reason');
    instructor1_reason.clearValidators();
    instructor1_reason.updateValueAndValidity();
  }
  
  onClickSoftwareIssue(){
    this.softwareOther = null;
    const softwareOther1 = this.digitalSakshar.get('softwareOther1');
    softwareOther1.clearValidators();
    softwareOther1.updateValueAndValidity();
  }

  onClickHardwareIssue(){    
    this.hardwareOther = null;
    const hardwareOther1 = this.digitalSakshar.get('hardwareOther1');
    hardwareOther1.clearValidators();
    hardwareOther1.updateValueAndValidity();
  }

  clearControll(){
    
    // this.q19=null;
    this.q20=null;
    this.q21=null;
    this.q22=null;
    this.q23=null;
    // this.q24=null;
    this.q25=null;
    // this.q26=null;

    // const teacherusingdsclassroomtypereason = this.digitalSakshar.get('teacherusingdsclassroomtypereason');
    // teacherusingdsclassroomtypereason.clearValidators();
    // teacherusingdsclassroomtypereason.updateValueAndValidity();
    
    const classaccordingdstt = this.digitalSakshar.get('classaccordingdstt');
    classaccordingdstt.clearValidators();
    classaccordingdstt.updateValueAndValidity();

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

    // const classaccordingdsttypereason = this.digitalSakshar.get('classaccordingdsttypereason');
    // classaccordingdsttypereason.clearValidators();
    // classaccordingdsttypereason.updateValueAndValidity();
   
    const useofvisitds = this.digitalSakshar.get('useofvisitds');
    useofvisitds.clearValidators();
    useofvisitds.updateValueAndValidity();
    
    // const useofvisitdsreason = this.digitalSakshar.get('useofvisitdsreason');
    // useofvisitdsreason.clearValidators();
    // useofvisitdsreason.updateValueAndValidity();

    const reason = this.digitalSakshar.get('reason');
    reason.clearValidators();
    reason.updateValueAndValidity();
    reason.reset();
    
    const remark = this.digitalSakshar.get('remark');
    remark.clearValidators();
    remark.updateValueAndValidity();
    remark.reset();

    const studentCount1 = this.digitalSakshar.get('studentCount1');
    studentCount1.clearValidators();
    studentCount1.updateValueAndValidity();
    studentCount1.reset();

  }

  clearControll_1(){
    
    this.q18='No';
    this.q20=null
    this.q21=null;
    this.q22=null;
    this.q23=null;
    // this.q24=null;
    this.q25=null;
    // this.q26=null;

    const teacherusingdsclassroom = this.digitalSakshar.get('teacherusingdsclassroom');
    teacherusingdsclassroom.clearValidators();
    teacherusingdsclassroom.updateValueAndValidity();
    
    const classaccordingdstt = this.digitalSakshar.get('classaccordingdstt');
    classaccordingdstt.clearValidators();
    classaccordingdstt.updateValueAndValidity();

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

    // const classaccordingdsttypereason = this.digitalSakshar.get('classaccordingdsttypereason');
    // classaccordingdsttypereason.clearValidators();
    // classaccordingdsttypereason.updateValueAndValidity();

    const useofvisitds = this.digitalSakshar.get('useofvisitds');
    useofvisitds.clearValidators();
    useofvisitds.updateValueAndValidity();

    // const useofvisitdsreason = this.digitalSakshar.get('useofvisitdsreason');
    // useofvisitdsreason.clearValidators();
    // useofvisitdsreason.updateValueAndValidity();
  }

  clearControl2(){

    this.q20 = 'No';
    this.q21=null;
    this.q22=null;
    this.q23=null;

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

    const studentCount1 = this.digitalSakshar.get('studentCount1');
    studentCount1.clearValidators();
    studentCount1.updateValueAndValidity();
    studentCount1.reset();

    const studPresentCount1 = this.digitalSakshar.get('studPresentCount1');
    studPresentCount1.clearValidators();
    studPresentCount1.updateValueAndValidity();
    studPresentCount1.reset();
  }

  clearControl2_(){
    
    this.q20='Yes';
    // this.q24=null;
    // const classaccordingdsttypereason = this.digitalSakshar.get('classaccordingdsttypereason');
    // classaccordingdsttypereason.clearValidators();
    // classaccordingdsttypereason.updateValueAndValidity();
  }

  clearControl3(){
    
    this.q25='No';
    this.q21=null;
    this.q22=null;
    this.q23=null;

    const whichclassttrunning = this.digitalSakshar.get('whichclassttrunning');
    whichclassttrunning.clearValidators();
    whichclassttrunning.updateValueAndValidity();

    const whichsubjectteaching = this.digitalSakshar.get('whichsubjectteaching');
    whichsubjectteaching.clearValidators();
    whichsubjectteaching.updateValueAndValidity();

    const whichtopicteaching = this.digitalSakshar.get('whichtopicteaching');
    whichtopicteaching.clearValidators();
    whichtopicteaching.updateValueAndValidity();

    const studentCount1 = this.digitalSakshar.get('studentCount1');
    studentCount1.clearValidators();
    studentCount1.updateValueAndValidity();
    studentCount1.reset();

    const studPresentCount1 = this.digitalSakshar.get('studPresentCount1');
    studPresentCount1.clearValidators();
    studPresentCount1.updateValueAndValidity();
    studPresentCount1.reset();

    if (this.isWorkingDevice == 'No' && this.q18 == 'No' && this.q25 == 'No') {

      const qa27 = this.digitalSakshar.get('dsmethod');
      const qa28 = this.digitalSakshar.get('qa28');
      const qa30 = this.digitalSakshar.get('qa30');
      const qa31 = this.digitalSakshar.get('qa31');
      const qa32 = this.digitalSakshar.get('qa32');
      const qa35 = this.digitalSakshar.get('qa35');
      const qa36 = this.digitalSakshar.get('qa36');
      const instructor1 = this.digitalSakshar.get('instructor1');
      const qa32_reason = this.digitalSakshar.get('qa32_reason');
      const instructor1_reason = this.digitalSakshar.get('instructor1_reason');
      const hardwareOther1 = this.digitalSakshar.get('hardwareOther1');
      const softwareOther1 = this.digitalSakshar.get('softwareOther1');
      const studentCount1 = this.digitalSakshar.get('studentCount1');
      const studPresentCount1 = this.digitalSakshar.get('studPresentCount1');

      qa27.clearValidators();
      qa27.updateValueAndValidity();
      qa27.reset();

      qa28.clearValidators();
      qa28.updateValueAndValidity();
      qa28.reset();

      qa30.clearValidators();
      qa30.updateValueAndValidity();
      qa30.reset();

      qa31.clearValidators();
      qa31.updateValueAndValidity();
      qa31.reset();

      qa32.clearValidators();
      qa32.updateValueAndValidity();
      qa32.reset();

      qa35.clearValidators();
      qa35.updateValueAndValidity();
      qa35.reset();

      qa36.clearValidators();
      qa36.updateValueAndValidity();
      qa36.reset();

      instructor1.clearValidators();
      instructor1.updateValueAndValidity();
      instructor1.reset();

      qa32_reason.clearValidators();
      qa32_reason.updateValueAndValidity();
      qa32_reason.reset();

      instructor1_reason.clearValidators();
      instructor1_reason.updateValueAndValidity();
      instructor1_reason.reset();

      hardwareOther1.clearValidators();
      hardwareOther1.updateValueAndValidity();
      hardwareOther1.reset();

      softwareOther1.clearValidators();
      softwareOther1.updateValueAndValidity();
      softwareOther1.reset();

      studentCount1.clearValidators();
      studentCount1.updateValueAndValidity();
      studentCount1.reset();

      studPresentCount1.clearValidators();
      studPresentCount1.updateValueAndValidity();
      studPresentCount1.reset();

    }
  }

  clearControl4(){

    this.q25='Yes';
    // this.q26=null;
    // const useofvisitdsreason = this.digitalSakshar.get('useofvisitdsreason');
    // useofvisitdsreason.clearValidators();
    // useofvisitdsreason.updateValueAndValidity();

    const reason = this.digitalSakshar.get('reason');
    reason.clearValidators();
    reason.updateValueAndValidity();
    reason.reset();
    
    const remark = this.digitalSakshar.get('remark');
    remark.clearValidators();
    remark.updateValueAndValidity();
    remark.reset();

  }

  update(){
  
    const data: DigitalSaksharForm = new DigitalSaksharForm();
    data.digitalclassroomno = this.q17;
    data.teacherusingdsclassroom = this.q18;
    // data.teacherusingdsclassroomtypereason = this.q19;
    data.classaccordingdstt = this.q20;
    data.whichclassttrunning = this.q21 ;
    data.whichsubjectteaching = this.q22;
    data.whichtopicteaching = this.q23;
    // data.classaccordingdsttypereason = this.q24;
    data.useofvisitds = this.q25;
    // data.useofvisitdsreason = this.q26;
    data.dsmethod = this.q27;
    data.teachername = this.q28;
    data.dsusinghelp = this.q30;
    data.dsusinghelpreason = this.q31;
    data.videorecordinginnotebook = this.q32;
    data.hardwareissue = this.q35;
    data.softwareissue = this.q36;
    data.hardwareissueotherreason = this.hardwareOther;
    data.softwareissueotherreason = this.softwareOther;
    data.videorecordinginnotebookreason = this.q32_reason;
    data.younginstructoractive = this.instructor;
    data.younginstructoractivereason = this.instructor_reason;
    data.availabledigitaldevices  = this.availabeDevice;
    data.availabledigitaldevicesoption = this.isWorkingDevice;
    data.studentcount = this.studentCount;
    data.studentpresentcount = this.studPresentCount;
    data.allnoreason = this.reason;
    data.allnoremark = this.remark;
    // data.availabledigitaldevicesreason = this.isWorkingDeviceReason;
    
    this.service.UpdateActionPointerMyEschool(this.formData.actionid,this.userid,data).subscribe((res)=>{
      
      if (res == 'Success') {
        this.loading = false;
        this.dialog.ngOnDestroy();
        window.location.reload();
      }
      else {
        this.loading = false;
        this.dialog.ngOnDestroy();
      }
    });
  }

  clear(){
    this.dialog.closeAll();
  }
}
