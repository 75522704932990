import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SchoolService } from 'src/app/shared/services/school.service';
import { Validators, FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-createbatchpopup',
  templateUrl: './createbatchpopup.component.html',
  styleUrls: ['./createbatchpopup.component.scss']
})
export class CreatebatchpopupComponent implements OnInit {
  schoolid;
  standardlist
  form: FormGroup;
  public btn = false;
  selectedItemsList = [];
 
  checkedIDs = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog,private service: SchoolService,private fb: FormBuilder,private toastr: ToastrService) {
    this.schoolid = data.id;

    this.service.GetSchoolStandard(this.schoolid).subscribe((data: any) => {
      this.standardlist = data;
   
   
     if(this.standardlist.length== 0 ){
      this.btn = false;
     }
     else{
     
      this.btn = true;
     }

    })
   }

  ngOnInit() {
    this.form = this.fb.group({
      checkArray: this.fb.array([], [Validators.required])
    })
   
    this.fetchSelectedItems()
      this.fetchCheckedIDs()
  }
  onCheckboxChange(e) {
    const checkArray: FormArray = this.form.get('checkArray') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  changeSelection() {
    this.fetchSelectedItems()
  }
   fetchSelectedItems() {
   
    this.selectedItemsList = this.standardlist.filter((value, index) => {
      return value.chk
    });
  }
 
  // IDs of selected item
  fetchCheckedIDs() {
    this.checkedIDs = []
    this.standardlist.forEach((value, index) => {
      if (value.chk) {
        this.checkedIDs.push(value.id);
      }
    });
   
  }

  submitForm() {
 
    this.fetchSelectedItems()
        this.service.AddCALBatch(this.schoolid,this.selectedItemsList).subscribe((res) => {
           this.toastr.success ("Batch Created SuccessFully");
           this.dialog.closeAll()
           window.location.reload();
          error => {
            console.log(error);
          }
       
        });

        }
}