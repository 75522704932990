import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-employeedependancymessages',
  templateUrl: './employeedependancymessages.component.html',
  styleUrls: ['./employeedependancymessages.component.scss']
})
export class EmployeedependancymessagesComponent implements OnInit {
    message;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog) {
        this.message = data.Dependacymessages;
   }

  ngOnInit() {
  }

}
