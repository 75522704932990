import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class EmployeeLeave {
  public cancelby: string = "";
  public remark: string = "";
  public empleaveid: string = "";
  public status: string = "";
}

@Component({
  selector: 'app-leavecancelpopup',
  templateUrl: './leavecancelpopup.component.html',
  styleUrls: ['./leavecancelpopup.component.scss']
})
export class LeavecancelpopupComponent implements OnInit {
  empname;
  empid;
  leavefrom;
  leaveto;
  leavetype;
  ActiveForm: FormGroup;
  loading;
  userid;
  empleaveid;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: MasterServiceService, private dialog: MatDialog, private fb: FormBuilder, private toastr: ToastrService) {
    console.log(data);
    
    this.empname = data.employeename;
    this.empid = data.empid;
    this.empleaveid = data.empleaveid;
    this.leavefrom = data.leavefrom;
    this.leaveto = data.leaveto;
    this.leavetype = data.leavetype;
    this.userid = localStorage.getItem("UserId");

  }

  ngOnInit() {
    this.ActiveForm = this.fb.group({
      remark: ['', Validators.required],
      leavecancel: [''],
    })
  }

  ChangeStatus() {
    this.loading = true;
    const data: EmployeeLeave = new EmployeeLeave();
    data.cancelby = this.userid;
    data.remark = this.ActiveForm.value.remark;
    data.empleaveid = this.ActiveForm.value.leavecancel;
    data.status = "Canceled";


    this.service.CancelEmployeeLeave(this.empleaveid, this.empid, data).subscribe((res: string) => {
      if (res == 'Cancelled') {
        this.loading = false;
        this.dialog.ngOnDestroy();
        this.toastr.success('Leave Cancellation Sucessfully!')
      }
      else {
        this.loading = false;
      }
    })
  }

  clear() {
    this.dialog.ngOnDestroy();
  }
}