import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedbackredirectionpopup',
  templateUrl: './feedbackredirectionpopup.component.html',
  styleUrls: ['./feedbackredirectionpopup.component.scss']
})
export class FeedbackredirectionpopupComponent implements OnInit {
  courseForm:FormGroup;

  constructor(private fb: FormBuilder,public dialog: MatDialog,private router: Router) { }

  ngOnInit() {
    this.courseForm = this.fb.group({
      course: ['', Validators.required],
    });
  }
 clear(){
   
     this.dialog.ngOnDestroy();
    }
    Redicrect(value){
      console.log(value.course);
        if(value.course == 'Secondary Computer Science'){
          this.router.navigate(['/ComputerScienceFeedback']);
        }
        else if(value.course == 'Primary Fun With English'){
          this.router.navigate(['/Primaryfeedback']);
        }
        else if(value.course == 'Soft Skill'){
          this.router.navigate(['/Softskillquestion']);
        }
        else if(value.course == 'Digital Skill For Parents'){
          this.router.navigate(['/MotherParentFeedback']);
        }
        else if(value.course == 'Digital Skill For Youth'){
          this.router.navigate(['/YouthDigitalSkillFeedback']);
        }
        else if(value.course == 'Digital Enhancement IDI'){
          this.router.navigate(['/EnhancementCourseFeedback']);
        }
        this.dialog.ngOnDestroy();
    }
}
