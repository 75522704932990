import { Component, OnInit, Inject } from '@angular/core';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CenterService } from 'src/app/shared/services/center.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-permissionpopup',
  templateUrl: './permissionpopup.component.html',
  styleUrls: ['./permissionpopup.component.scss']
})
export class PermissionpopupComponent implements OnInit {
  uaccess: boolean;
  uemail = false;
  cassign = false;
  access;
  email;
  permissionForm:FormGroup;
  designation;
  reporttoid;
  empid;
  centerlist;
  pgid;
  depid;
  center;
  userid;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: MasterServiceService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute,private fb:FormBuilder,private centservice:CenterService,private toastr: ToastrService) { 
    this.pgid= data.pgid;
    this.depid= data.depid;
   this.designation= data.desid;
   this.reporttoid= data.reportsto;
   this.empid= data.empid;
   this.userid = localStorage.getItem("UserId");
   
   this.centservice.GetCenterTLDetail(this.reporttoid).subscribe((data:any)=>{
    this.centerlist = data;
   })

   this.service.FillDropDown("UserMaster", "distinct username", "userid", " where UserMaster.status='Active' and  UserMaster.userid='"+this.empid+"'").subscribe((data: any) => {
      
    if(data.length == 0){
      this.uaccess = false;
    }
    else{
      this.uaccess = true;
    }
  })
     }
  ngOnInit() {
    this.permissionForm = this.fb.group({
      center: ['', Validators.required],
    })
  }
  clear() {
    this.dialog.ngOnDestroy();
  }
  changeSelection1(value: boolean) {
    this.uaccess = value;
    this.access = this.uaccess == true?'Yes':'No';

  }
  changeSelection2(value: boolean) {
    this.uemail = value;
    this.email = this.uemail == true?'Yes':'No';


  }
  changeSelection3(value: boolean) {
    this.cassign = value;
  }

  submit() {
   
    var obj={
      pgid: this.pgid,
      depid:this.depid,
      desid:this.designation,
      centid:this.center,
      uaccess:this.access,
      eaccess:this.email,
      empid:this.empid,
      userid:this.userid
    }


    this.service.CreateUser(obj).subscribe((res) => {
     
        this.toastr.success(res.toString());
        this.dialog.ngOnDestroy();
    },
      error => {
        console.log(error);
      });
    
  }
}
