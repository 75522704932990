// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: 'https://api.jankari.org.in/api/',
  //apiUrl: 'https://demoapi.jankari.org.in/api/',
  apiUrlAD: 'https://adoreapi.jankari.org.in/',
  //apiUrl: 'http://localhost:24214/api/',
  apiUrl1: 'https://dsexam.digitalsakshar.com/api/',
  // apiUrl1: "http://localhost:30647/api/",
  DSapiUrl: 'https://dsapi.digitalsakshar.com//api/',

};
