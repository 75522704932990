import { Component, OnInit ,Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-dailyworksummary',
  templateUrl: './dailyworksummary.component.html',
  styleUrls: ['./dailyworksummary.component.scss']
})
export class DailyworksummaryComponent implements OnInit {
  Userid;
  date;
  DailyWork;
  DailyWorkcount;
  Worksummary;
  Worksummarycount;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private service: MasterServiceService,public dialog: MatDialog) {
        this.Userid = data.userid;
        this.date = data.date;
    this.service.GetUserDailyTaskList("and umain.userid='" + this.Userid + "' and convert (varchar,umain.reportdate,103)=convert(varchar,'" + this.date + "',103)").subscribe((data:any)=>{this.DailyWork=data;
    })
    this.service.GetUserDailyTaskList("and umain.userid='" + this.Userid + "' and convert (varchar,umain.reportdate,103)=convert(varchar,'" + this.date + "',103)").subscribe((data: any) => {  this.DailyWorkcount= data.length;})
    this.service.GetUserDailyReportList("where  Convert(date,UserDailyReport.reportdate,103)=convert(date,'" + this.date + "',103) and UserDailyReport.userid='" +this.Userid+ "'").subscribe((data:any)=>{this.Worksummary=data;
      
      });
   }

  ngOnInit() {
   
  }
  clear(){
   
    this.dialog.ngOnDestroy();
   }
}
