import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'app-attendancewarningpopup',
  templateUrl: './attendancewarningpopup.component.html',
  styleUrls: ['./attendancewarningpopup.component.scss']
})
export class AttendancewarningpopupComponent implements OnInit {

  constructor(public dialog: MatDialog ,public card:MatCardModule) {
 
    
  }

  ngOnInit() {
  }

}
