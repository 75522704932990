export class Forgetpassword {
    constructor(
       
        public username  :string="",
        public dummypass :string="",
        
     
   
    ){}
}
