import { Component, OnInit ,Inject} from '@angular/core';
import { MasterServiceService } from 'src/app/shared/services/master-service.service';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-salarybrekuppopup',
  templateUrl: './salarybrekuppopup.component.html',
  styleUrls: ['./salarybrekuppopup.component.scss']
})
export class SalarybrekuppopupComponent implements OnInit {
empid;
emptype;
month;
year;
salaryslip;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private service: MasterServiceService,public dialog: MatDialog,private router: Router,private route: ActivatedRoute) {


this.empid = data.empid;
this.emptype = data.emptype;
this.month = data.month;
this.year = data.year;

service.GetSalarySlipPrintData("where Salarymaster.salmonth='" + this.month + "' and Salarymaster.salyear='" + this.year + "' and Employee.empid='" + this.empid + "'").subscribe((data: any) => {
  this.salaryslip = data; 
});
   }

  ngOnInit() {
  }

}
